import React, {Fragment} from 'react'

import "./HerderFormulario.css"

const HerderFormulario = ( {titulo, modalCancel} ) => {
    return ( 
        <Fragment>
            {titulo}
            <span style={{ float: "right" }} onClick={() => modalCancel()}>X</span>
        </Fragment>
    );
}
 
export default HerderFormulario;
