import axios from "axios";

const apiURLBase = process.env.REACT_APP_BACKEND_URL;

const _imprimiConsulta = (url) => {
    if(process.env.REACT_APP_ENTORNO === "DESA"){
        console.log(url);
    }
}

// Busca si el tarjetaUsuario esta registrado
export const getTarjetaUsuarioById = async (idTarjetaUsuario) => {
    const url = apiURLBase + "tarjetaUsuario.php?idTarjetaUsuario=" + idTarjetaUsuario;  
    _imprimiConsulta(url);
    const { data } = await axios.get(url);
    return data;
};

// Busca si el tarjetaUsuario esta registrado
export const getTarjetaUsuarioByTarjetaCodigo = async (idTarjetaCodigo) => {
    const url = apiURLBase + "tarjetaUsuario.php?idTarjetaCodigo=" + idTarjetaCodigo;  
    _imprimiConsulta(url);
    const { data } = await axios.get(url);
    return data;
};

// Busca si el tarjetaUsuario esta registrado
export const getTarjetaUsuarioByCodigo = async (codigo) => {
    const url = apiURLBase + "tarjetaUsuario.php?codigo=" + codigo;  
    _imprimiConsulta(url);
    const { data } = await axios.get(url);
    return data;
};

// Registra un nuevo tarjetaUsuario
export const postRegistroTarjetaUsuario = async (modeloTarjetaUsuario) => {
    const url = apiURLBase + "tarjetaUsuario.php";  
    _imprimiConsulta(url);
    const { data } = await axios.post(url, modeloTarjetaUsuario);
    return data;
};

// Actualizar los datos de un tarjetaUsuario
export const putRegistroTarjetaUsuario = async (modeloTarjetaUsuario) => {
    const url = apiURLBase + "tarjetaUsuario.php";  
    _imprimiConsulta(url);
    const { data } = await axios.put(url, modeloTarjetaUsuario);
    return data;
};

export const deleteTarjetaUsuarioById = async (idTarjetaUsuario) => {
    const url = apiURLBase + "tarjetaUsuario.php?idTarjetaUsuario=" + idTarjetaUsuario;  
    _imprimiConsulta(url);
    const { data } = await axios.delete(url);
    return data;
};

export const deleteTarjetaUsuarioByCodigoTarjeta = async (idTarjetaCodigo) => {
    const url = apiURLBase + "tarjetaUsuario.php?idTarjetaCodigo=" + idTarjetaCodigo;  
    _imprimiConsulta(url);
    const { data } = await axios.delete(url);
    return data;
};

////////////////////////// SECCIONES DE UNA TARJETA DE USUARIO //////////////////////////////////

// Busca si el tarjetaUsuarioSeccion esta registrado
export const getRegistroTarjetaUsuarioSeccion = async (idTarjetaUsuario) => {
    const url = apiURLBase + "tarjetaUsuarioSeccion.php?idTarjetaUsuario=" + idTarjetaUsuario;
    _imprimiConsulta(url);
    const { data } = await axios.get(url);
    return data;
}

// Busca si el tarjetaUsuarioSeccion esta registrado sin Preview
export const getRegistroTarjetaUsuarioSeccionSinPreview = async (idTarjetaUsuario) => {
    const url = apiURLBase + "tarjetaUsuarioSeccionSinPreview.php?idTarjetaUsuario=" + idTarjetaUsuario;
    _imprimiConsulta(url);
    const { data } = await axios.get(url);
    return data;
}

// Registra un nuevo tarjetaUsuarioSeccion
export const postRegistroTarjetaUsuarioSeccion = async (modeloTarjetaUsuarioSeccion) => {
    const url = apiURLBase + "tarjetaUsuarioSeccion.php";  
    _imprimiConsulta(url);
    const { data } = await axios.post(url, modeloTarjetaUsuarioSeccion);
    return data;
};

// Actualizar los datos de un tarjetaUsuarioSeccion
export const putRegistroTarjetaUsuarioSeccion = async (modeloTarjetaUsuarioSeccion) => {
    const url = apiURLBase + "tarjetaUsuarioSeccion.php";  
    _imprimiConsulta(url);
    const { data } = await axios.put(url, modeloTarjetaUsuarioSeccion);
    return data;
};

// Delete los datos de un tarjetaUsuarioSeccion por ID
export const deleteTarjetaUsuarioSeccionById = async (idTarjetaUsuarioSeccion) => {
    const url = apiURLBase + "tarjetaUsuarioSeccion.php?idTarjetaUsuarioSeccion=" + idTarjetaUsuarioSeccion;  
    _imprimiConsulta(url);
    const { data } = await axios.delete(url);
    return data;
};

// Delete los datos de un tarjetaUsuarioSeccion por tarjeta usuario
export const deleteTarjetaUsuarioSeccionByTarjetaUsuario = async (idTarjetaUsuario) => {
    const url = apiURLBase + "tarjetaUsuarioSeccion.php?idTarjetaUsuario=" + idTarjetaUsuario;  
    _imprimiConsulta(url);
    const { data } = await axios.delete(url);
    return data;
};