import { fabric } from 'fabric'
import lottie from 'lottie-web'

fabric.LottieAC = fabric.util.createClass(fabric.Image, {
  type: 'lottieAC',
  lockRotation: false,
  lockSkewingX: false,
  lockSkewingY: false,
  srcFromAttribute: false,

  initialize: function (path, options) {
    if (options.width === undefined) options.width = 1200
    if (options.height === undefined) options.height = 1200
    if (options.bloqueado_ac === undefined) options.bloqueado_ac = false
    if (options.loop_ac === undefined) options.loop_ac = true
    if (options.autoplay_ac === undefined) options.autoplay_ac = true
    if (options.ratio_ac === undefined) options.ratio_ac = 75
    if (options.estadoEjecucion === undefined) options.estadoEjecucion = 0

    //this.path = path
    this.path = path
    this.tmpCanvasEl = fabric.util.createCanvasElement()
    this.tmpCanvasEl.width = options.width
    this.tmpCanvasEl.height = options.height

    this.bloqueado_ac = options.bloqueado_ac
    this.loop_ac = options.loop_ac
    this.autoplay_ac = options.autoplay_ac
    this.ratio_ac = options.ratio_ac

    this.estadoEjecucion = options.estadoEjecucion

    this.lottieItem = null; // We'll initialize it later

    this.callSuper('initialize', this.tmpCanvasEl, options);

    options && this.set('bloqueado_ac', options.bloqueado_ac);
    options && this.set('loop_ac', options.loop_ac);
    options && this.set('autoplay_ac', options.autoplay_ac);
    options && this.set('ratio_ac', options.ratio_ac);
    options && this.set('estadoEjecucion', options.estadoEjecucion);

    // Lazy load the Lottie animation when needed
    this.loadLottieAnimation();

    // Agrega el event listener después de cargar la animación
    this.addEnterFrameListener();
  },

  // New function to load Lottie animation
  loadLottieAnimation: function () {
    if (!this.lottieItem) {
      // Load the Lottie animation only if it hasn't been loaded yet
      this.lottieItem = lottie.loadAnimation({
        renderer: 'canvas',
        loop: this.loop_ac,
        autoplay: this.autoplay_ac,
        path: this.path,
        rendererSettings: {
          context: this.tmpCanvasEl.getContext('2d'),
          preserveAspectRatio: 'xMidYMid meet',
        },
      });

    }
  },

  // Nuevo método para agregar el event listener
  addEnterFrameListener: function () {
    if (this.lottieItem) {
      this.lottieItem.addEventListener('enterFrame', (e) => {
        if( this.estadoEjecucion === 0 ){
          this.canvas?.requestRenderAll();
          //console.log("************************** refrese ************************** " );
          //console.log(this.lottieItem.fileName + " - " + this.lottieItem.loop);
        }        
      });
    }
  },

  // Nuevo método para eliminar el event listener
  removeEnterFrameListener: function () {
    if (this.lottieItem) {
      this.lottieItem.removeEventListener('enterFrame', (e) => {

      });
    }
  },

  toObject: function() {
    return fabric.util.object.extend(this.callSuper('toObject'), 
      { 
        bloqueado_ac: this.bloqueado_ac,
        loop_ac: this.loop_ac, 
        ratio_ac: this.ratio_ac, 
        autoplay_ac: this.autoplay_ac}
    );
  },

  play: function () {
    this.lottieItem.play()
    // Agrega el event listener cuando se inicia la animación
    this.estadoEjecucion = 0;
    this.addEnterFrameListener();
  },
  pause: function () {
    this.lottieItem.pause()
    // Elimina el event listener cuando se pausa la animación
    this.estadoEjecucion = 1;
    this.removeEnterFrameListener();
  },
  stop: function () {
    this.lottieItem.stop()
    this.removeEnterFrameListener();
    this.lottieItem.destroy();
  },
  getLoop: function () {
    return this.lottieItem.loop;
  }, 
  changeLoop: function () {
    if(this.lottieItem.loop  === true ){
      this.loop_ac = false;
      this.lottieItem.loop = false;
    } else {
      this.loop_ac = true;
      this.lottieItem.loop = true;
    }
  },
  getAutoplay: function () {
    return this.lottieItem.autoplay;
  }, 
  changeAutoplay: function () {
    if(this.lottieItem.autoplay  === true ){
      this.autoplay_ac = false;
      this.lottieItem.autoplay = false;
    } else {
      this.autoplay_ac = true;
      this.lottieItem.autoplay = true;
    }
  },
  getBloqueado: function () {
    return this.bloqueado_ac;
  }, 
  changeBloqueado: function () {
    if(this.bloqueado_ac  === true ){
      this.bloqueado_ac = false;
    } else {
      this.bloqueado_ac = true;
    }
  },
  changeRatio: function(ratio){
    this.ratio_ac = ratio;
  },
  getRatio: function () {
    return this.ratio_ac;
  }, 
  playRatio: function(ratio){
    if( this.ratio_ac <= ratio ){
      this.play();
    }
  },  
  getSrc: function () {
    return this.path
  }, 
});

fabric.LottieAC.fromObject = function (_object, callback) {
  const object = fabric.util.object.clone(_object);
  fabric.Image.prototype._initFilters.call(object, object.filters, function (filters) {
    object.filters = filters || []
    fabric.Image.prototype._initFilters.call(object, [object.resizeFilter], function (resizeFilters) {
      object.resizeFilter = resizeFilters[0]
      fabric.util.enlivenObjects([object.clipPath], function (enlivedProps) {
        object.clipPath = enlivedProps[0]
        const fabricLottie = new fabric.LottieAC(object.src, object)
        callback(fabricLottie, false)
      })
    })
  })
}

fabric.LottieAC.async = true;

export default fabric.LottieAC;