import {create} from "zustand";
import { mountStoreDevtool } from 'simple-zustand-devtools';

export const useStoreDatosPortada = create((set, get) => ({
    loginCampo1: '',
    loginCampo2: '',
    loginCampo3: '',

    updateLoginCampo1: (newLoginCampo1) => {
        set({loginCampo1: newLoginCampo1})
    },

    updateLoginCampo2: (newLoginCampo2) => {
        set({loginCampo2: newLoginCampo2})
    },

    updateLoginCampo3: (newLoginCampo3) => {
        set({loginCampo3: newLoginCampo3})
    },

    removeUsuario: () => set({}, true),
}));

if(process.env.REACT_APP_ENTORNO === "DESA"){
    mountStoreDevtool('useStoreDatosPortada', useStoreDatosPortada);
}

