import axios from "axios";

const apiURLBase = process.env.REACT_APP_BACKEND_URL;

const _imprimiConsulta = (url) => {
    if(process.env.REACT_APP_ENTORNO === "DESA"){
        console.log(url);
    }
}

// Busca si el fondo de tarjeta esta registrado
export const getModeloTarjetaById =  async (idModeloTarjeta) => {
    const url = apiURLBase + "modeloTarjeta.php?idModeloTarjeta=" + idModeloTarjeta;
    _imprimiConsulta(url);
    const { data } = await axios.get(url);
    return data;
}

