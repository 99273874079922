import React, {useEffect, Fragment} from "react";
import {Routes, Route} from "react-router-dom"
// Boostrap estilo -- IMPORTANTE !!!!!!!!!!!!!!!!
import "bootstrap/dist/css/bootstrap.min.css";
// Estilo
import "./App.css"

// Rutas Modelador Cliente - Begin
import Home from './Aplicacion/Paginas/Home/Home';

import ModellaEscritorio from './Aplicacion/Paginas/EditorUsuario/Escritorio/Escritorio';
import ModellaMobile from './Aplicacion/Paginas/EditorUsuario/Mobile/Mobile';

import LoginPersonalizadoCreador from './Aplicacion/Paginas/LoginPersonalizadoCreador/LoginPersonalizadoCreador';

import ListaInvitados from './Aplicacion/Paginas/ListadoInvitados/Lista/ListaInvitados';
import EditarInvitado from "./Aplicacion/Paginas/ListadoInvitados/Editar/EditarInvitado";

import ListaLoginPesonalizado from "./Aplicacion/Paginas/ListaLoginPersonalizado/Lista/ListaLoginPesonalizado";
import EditarLoginPesonalizado from "./Aplicacion/Paginas/ListaLoginPersonalizado/Editar/EditarLoginPesonalizado";
import AgregarLoginPesonalizado from "./Aplicacion/Paginas/ListaLoginPersonalizado/Agregar/AgregarLoginPesonalizado";
// Rutas Modelador Cliente - End

// Importa los fonts para los textos
import  FontFaceObserver from 'fontfaceobserver';

import Prueba1 from './pruebas/prueba1/Prueba1.jsx';

function App() {
  
  useEffect( () => {

    console.log("***** Entorno: " + process.env.REACT_APP_ENTORNO + "******")

    // Metodo exclusivo para asignar FUENTES a un compomente de tipo ITEXT
    const loadFontObserver = async (value) => {

      Promise.all([new FontFaceObserver(value).load(),
        ]).then( () => {
          //console.log("FUENTE CARGADA " + value);
        }, err => {
          
        })
    }   

    const loadFuentes = async () => {
      await loadFontObserver('MoniqueScriptRegular');
      await loadFontObserver('TypewriterCondensed');
      await loadFontObserver('AbrilFatface-Regular');
      await loadFontObserver('LearningCurve');
      await loadFontObserver('ArimaMaduraiLight');
      await loadFontObserver('ArimaMaduraiRegular');
      await loadFontObserver('vijaya');
      await loadFontObserver('AparajitaRegular');
      await loadFontObserver('unicodeoptima');
      await loadFontObserver('DKLemonYellowSun');
      await loadFontObserver('CupiddeLocke');
      await loadFontObserver('AlexBrush');
      await loadFontObserver('Parisienne');
      await loadFontObserver('ClickerScript');
      await loadFontObserver('ChalisaOctavia');
      await loadFontObserver('Edwardian');
      await loadFontObserver('CandlescriptDemo');
      await loadFontObserver('ChopinScript');
      await loadFontObserver('Begokly');
      await loadFontObserver('Melinda');
      await loadFontObserver('Aisyah');
      await loadFontObserver('MyriadPro');
      await loadFontObserver('RougeScript');
      await loadFontObserver('RobotoLight');
      await loadFontObserver('Cinzel');
      await loadFontObserver('Royante');
      await loadFontObserver('alsscrp');
      await loadFontObserver('Chorettan');
      await loadFontObserver('PadukaScript');
      await loadFontObserver('Ancukaseyo');
      await loadFontObserver('aishiterulover');
      await loadFontObserver('almyra');
      await loadFontObserver('amberlandscript');
      await loadFontObserver('ballet');
      await loadFontObserver('bellanaisa');
      await loadFontObserver('brushpenstory');
      await loadFontObserver('carrington');
      await loadFontObserver('elisabetta');
      await loadFontObserver('frutillascript');
      await loadFontObserver('hottempslant');
      await loadFontObserver('hugtophia');
      await loadFontObserver('ontel');
      await loadFontObserver('sweetnessscript');
      await loadFontObserver('symphonyscript');
      await loadFontObserver('woodland');
      await loadFontObserver('zaheera');
      await loadFontObserver('LearningCurve');
      await loadFontObserver('daniela');
      await loadFontObserver('flawless');
      await loadFontObserver('rhscript');
      await loadFontObserver('royalia');
      await loadFontObserver('shears');
      await loadFontObserver('allegro');
      await loadFontObserver('canteen');
      await loadFontObserver('caviardreams');
      await loadFontObserver('festivo17');
      await loadFontObserver('festivo7');
      await loadFontObserver('happycamper');
      await loadFontObserver('hottempdisplay');
      await loadFontObserver('lowerxeight');
      await loadFontObserver('merriweatherregular');
      await loadFontObserver('simplesans');
      await loadFontObserver('euphorigenic');
      await loadFontObserver('holidayjoybold');
      await loadFontObserver('libertyandlove');
      await loadFontObserver('marta');
      await loadFontObserver('sanek');
      await loadFontObserver('scopeone');
      await loadFontObserver('xanthie');
      await loadFontObserver('Gabriola');
      await loadFontObserver('times');
      await loadFontObserver('Windsor_Great_Park');
      await loadFontObserver('AYearWithoutRain');
      await loadFontObserver('Tangerine-Regular');
      await loadFontObserver('SweetieRomance');
      await loadFontObserver('SmoothLoving');
      await loadFontObserver('Darrel_Allura');
      await loadFontObserver('Airthay');
      await loadFontObserver('Magnolia');
      await loadFontObserver('Silenter');
      await loadFontObserver('cac_champagne');
      await loadFontObserver('Amelia_Giovani');
      await loadFontObserver('Dulcelin');
      await loadFontObserver('KUNSTLER');
      await loadFontObserver('Wellthington');
      await loadFontObserver('Winter_Calligraphy');
      await loadFontObserver('Darleston');
      await loadFontObserver('Cookie_Regular');
      await loadFontObserver('Lavanderia_Regular');
    }

    loadFuentes();
  })

  return (
    <Fragment>
      <Routes>

        {/* Login personalizado simple */}
        <Route path="LPCreator" element={<LoginPersonalizadoCreador/>}></Route>

        {/* Tarjeta */}
        <Route path="t" element={<Home/>}/> 

        {/* Rutas para acceder al modelador */}
        <Route path="me" element={<ModellaEscritorio/> } exact></Route>
        <Route path="mb" element={<ModellaMobile/> } exact></Route>

        {/* Rutas para acceder al listado de invitados */}
        <Route path="LI" element={<ListaInvitados/> } exact></Route>
        <Route path="editarInvitado/:id/:hashPublico/:hashPrivado/:idioma" element={<EditarInvitado/> } exact></Route>
        
        {/* Rutas para acceder al login personalizado PREMIUM con URL corta */}
        <Route path="LP" element={<ListaLoginPesonalizado/> } exact></Route>
        <Route path="editarLoginPesonalizado/:id/:codigo/:idioma" element={<EditarLoginPesonalizado/> } exact></Route>        
        <Route path="agregarLoginPesonalizado/:codigo/:idioma" element={<AgregarLoginPesonalizado/> } exact></Route>        
        
        {/* Rutas para acceder a pruebas */}
        {/* <Route path="p1" element={<Prueba1/>}></Route>               */}

        {/* Ruta por defecto si no hay coincidencia con ninguna anterior */}
        <Route path="*" element={<>
          <h1>PAGINA NO EXISTE</h1>
        </>} />

      </Routes>
    </Fragment>  
  );
}

export default App;


