import { useEffect, useState, Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// Componente internacionalizacion
import { useTranslation } from 'react-i18next';
// Muestra el componente de loadging
import Loading from '../../Componentes/Loading/Loading';

// Gestion de tarjeta codigo del backend
import { getRegistroTarjetaCodigoByCodigo } from "../../../servicios/axios/restApi/useApiTarjetaCodigo";
// Gestion de tarjeta usuario del backend
import { getTarjetaUsuarioByTarjetaCodigo, getRegistroTarjetaUsuarioSeccionSinPreview } from "../../../servicios/axios/restApi/useApiTarjetaUsuario";
// Gestion de fondos de tarjeta del backend
import { getAllFondosTarjetasByModelo } from "../../../servicios/axios/restApi/useApiFondoTarjeta";
// Gestion de modelos de tarjeta
import { getModeloTarjetaById } from "../../../servicios/axios/restApi/useApiModeloTarjeta";

// Toast 
import { ToastContainer } from 'react-toastify';
import { showSuccessToast, showErrorToast } from "../../../servicios/toast/toast";

// Zustand Store 
import { useStoreUsuario } from '../../../servicios/stores/usuario/useStoreUsuario';
import { useStoreModella } from '../../..//servicios/stores/modella/useStoreModella';

// Fabricjs - Modella
import Escritorio from './../EditorUsuario/Escritorio/Escritorio';
import Mobile from "../EditorUsuario/Mobile/Mobile";

import MobileInicioPantalla from "../EditorUsuario/Mobile/MobileInicioPantalla";

import './Home.css';

const Home = () => {
    let { search } = useLocation();
    let query = new URLSearchParams(search);

    // Cambio de idioma
    const [translate, i18n] = useTranslation('global');

    // Variables de componente
    const navigate = useNavigate();

    // *** Zustand - BEGIN ***
    const updateCodigoTarjetaStore = useStoreModella((state) => state.updateCodigoTarjeta);
    const updateDatosTarjetaUsuarioSeccion = useStoreModella((state) => state.updateDatosTarjetaUsuarioSeccion);
    const updateDatosFondoTarjeta = useStoreModella((state) => state.updateDatosFondoTarjeta);
    const updateEdicionFinalizada = useStoreModella((state) => state.updateEdicionFinalizada);
    const updateReset = useStoreModella((state) => state.updateReset);
    // *** Zustand - END ***

    // Indica si hay que mostrar la pantalla de login
    const [mostrarPantallaLogin, setMostrarPantallaLogin] = useState(true);
    // use Audio constructor to create HTMLAudioElement
    const [audioTune, setAudioTune] = useState("");
    // nombre del temas musical
    const [audioNombre, setAudioNombre] = useState("");
    // variable to play audio in loop
    const [playInLoop, setPlayInLoop] = useState(true);

    // Variables de componente
    const [isLoading, setIsLoading] = useState(false);

    // Ancho pantalla para determinar si es celular o notebook
    const [anchoPantalla, setAnchoPantalla] = useState(false);

    // Codigo de tarjeta ingresada por el usuario
    const [codigoIngresado, setCodigoIngresado] = useState('');

    // Idioma recibido por URL
    const [idioma, setIdioma] = useState('ES');

    // Indica si se ha terminado la edicion de una tarjeta
    const [isEdicionFinalizada, setIsEdicionFinalizada] = useState('0');

    // Estructura de datos
    const modeloTarjetaCodigo = {
        idTarjetaCodigo: '',   // Primary Key del registro
        tarjetaCodigo: '',     // Codigo de tarjeta a utilizar por el cliente
        idTarjetaUsuario: ''   // Indica el template de tarjeta asociado a este código
    }
    const [tarjetaCodigo, setTarjetaCodigo] = useState(modeloTarjetaCodigo);

    const modeloTarjetaUsuario = {
        idTarjetaUsuario: '',       // Primary key - Id de la tarjeta de usuario autoincremental
        idTarjetaCodigo: '',        // Foreing Key - Código de tarjeta
        idUsuario: '',              // Foreing Key - Usuario que asociado a la tarjeta
        isEdicionFinalizada: 1, // Indica si se permite la edición de la tarjeta
        // 0 - Edicion en curso
        // 1  - Edición Finalizada
        idModeloTarjeta: '',
        descripcionTarjetaUsuario: '',
        nombreTarjetaUsuario: '',
        urlMusica: '',
        loginCampo1: '',
        loginCampo2: '',
        loginCampo3: '',
    }
    const [tarjetaUsuario, setTarjetaUsuario] = useState(modeloTarjetaUsuario);

    const [seccionesTarjetaUsuario, setSeccionesTarjetaUsuario] = useState([]);

    const [fondosDePantallasTarjeta, setFondosDePantallasTarjeta] = useState([]);

    const [colorFlechaDeslizar, setColorFechaDeslizar] = useState('#4d4d4d');

    useEffect(() => {
        try {

            const buscarCodigoProducto = async (codigoIngresado) => {
                const tarjetaCodigoAux = await getRegistroTarjetaCodigoByCodigo(codigoIngresado);
                updateCodigoTarjetaStore(codigoIngresado);

                if (tarjetaCodigoAux.length > 0) {
                    setTarjetaCodigo(tarjetaCodigoAux[0]);
                    // Busca si el código de producto ya esta asociado a un cliente, o se lo asigna según corresponda.
                    await buscarTarjetaUsuario(tarjetaCodigoAux[0]);
                } else {
                    showErrorToast("Código de producto no valido");
                }
            }

            const buscarTarjetaUsuario = async (tarjetaCodigo) => {
                const datosTarjetaUsuarioAux = await getTarjetaUsuarioByTarjetaCodigo(tarjetaCodigo.idTarjetaCodigo);

                if (datosTarjetaUsuarioAux.length > 0) {
                    setTarjetaUsuario(datosTarjetaUsuarioAux[0]);

                    setAudioTune(new Audio(datosTarjetaUsuarioAux[0].urlMusica));
                    setAudioNombre(datosTarjetaUsuarioAux[0].urlMusica);

                    // Guardo en forma local
                    setIsEdicionFinalizada(datosTarjetaUsuarioAux[0].isEdicionFinalizada);
                    // Guardo el valor en zustand para usar en el editos modella.
                    updateEdicionFinalizada(datosTarjetaUsuarioAux[0].isEdicionFinalizada)
                    // Busca las secciones de la tarjeta
                    await buscarSeccionesTarjetaUsuario(datosTarjetaUsuarioAux[0]);
                } else {
                    showErrorToast("Código sin tarjeta asociada.");
                }
            }

            const buscarSeccionesTarjetaUsuario = async (tarjetaUsuarioAux) => {
                const seccionesTarjetaUsuarioAux = await getRegistroTarjetaUsuarioSeccionSinPreview(tarjetaUsuarioAux.idTarjetaUsuario);

                if (seccionesTarjetaUsuarioAux.length > 0) {

                    const seccionesTarjetaUsuarioOrdenadasAux = [...seccionesTarjetaUsuarioAux].sort((a, b) => {
                        if (parseInt(a.numeroDeSeccionTarjetaUsuario) < parseInt(b.numeroDeSeccionTarjetaUsuario)) {
                            return -1;
                        }
                    }
                    );

                    // Corrige el orden por si alguna sección ha sido desabilitada.
                    seccionesTarjetaUsuarioOrdenadasAux.forEach(
                        (seccionTarjetaUsuarioOrdenadasAux, index) => {
                            seccionTarjetaUsuarioOrdenadasAux.numeroDeSeccionTarjetaUsuario = index + 1
                        }
                    );

                    setSeccionesTarjetaUsuario(seccionesTarjetaUsuarioOrdenadasAux);

                    // Busco los fondos de tarjetas vinculados al modelos de tarjeta
                    await buscarFondosDeTarjetaByModelo(tarjetaUsuarioAux.idModeloTarjeta);

                    // Busco el colore de las flechas guias de deslizar
                    await buscarModeloDeTarjetaByModelo(tarjetaUsuarioAux.idModeloTarjeta);

                } else {
                    showErrorToast("No existe secciones de tarjeta asociados.");
                }
            }

            const buscarModeloDeTarjetaByModelo = async (idModeloTarjeta) => {
                const modeloDeTarjetaAux = await getModeloTarjetaById(idModeloTarjeta);

                if (modeloDeTarjetaAux.length > 0) {
                    if(modeloDeTarjetaAux[0].colorFlechasDeslizarInv) {
                        if(modeloDeTarjetaAux[0].colorFlechasDeslizarInv.length > 0){
                            setColorFechaDeslizar(modeloDeTarjetaAux[0].colorFlechasDeslizarInv);
                        }                        
                    }
                } else {
                    showErrorToast("No existe modelo de tarjeta asociados.");
                }
            }

            const buscarFondosDeTarjetaByModelo = async (idModeloTarjeta) => {
                const fondosDePantallasAux = await getAllFondosTarjetasByModelo(idModeloTarjeta);

                if (fondosDePantallasAux.length > 0) {
                    setFondosDePantallasTarjeta(fondosDePantallasAux);
                } else {
                    showErrorToast("No existe fondos de tarjeta asociados.");
                }
            }

            const cargarTarjeta = async (codigo) => {
                setIsLoading(true);
                await buscarCodigoProducto(codigo);
                setIsLoading(false);
            }

            setAnchoPantalla(window.innerWidth);

            //Recupero el idioma desde la URL
            if (query.get("idioma")) {
                setIdioma(query.get("idioma"));
                console.log(query.get("idioma"))
                if (query.get("idioma") === 'ES') {
                    i18n.changeLanguage('es');
                } else if (query.get("idioma") === 'IT') {
                    i18n.changeLanguage('it');
                } else {
                    i18n.changeLanguage('en');
                }
            } else {
                setIdioma("ES");
                i18n.changeLanguage('es');
                console.log("Idioma no definido, se asigna ES");
            }

            setCodigoIngresado(query.get("codigo"));
            cargarTarjeta(query.get("codigo"));

        } catch (e) {
            setIsLoading(false);
            showErrorToast("No se pudo recurperar los datos del servidor.");
        }
    }, []); // useState - END



    const buscarFondoDeSeccionTarjetaById = (idFondoTarjetaAux) => {
        let modeloFondoTarjeta = {
            idFondoTarjeta: '',              // Primary Key
            idModeloTarjeta: '',             // Nombre de la tarjeta a la que pertenece
            descripcion: '',                 // Descripcion - función de la imagen (portada, datos, cierre, etc..)
            fondoTarjetaConMarcaDeAgua: '',  // imagenBase64 con marca de aguar
            fondoTarjetaSinMarcaDeAgua: '',  // imagenBase64 sin marca de agua
        }

        if (fondosDePantallasTarjeta.length > 0) {
            modeloFondoTarjeta = fondosDePantallasTarjeta.find(fondoPantalla => fondoPantalla.idFondoTarjeta === idFondoTarjetaAux)
            //console.log(modeloFondoTarjeta);
            if (isEdicionFinalizada === '0') {
                return modeloFondoTarjeta.fondoTarjetaConMarcaDeAgua;
            } else {
                return modeloFondoTarjeta.fondoTarjetaSinMarcaDeAgua;
            }
        }

        return '';
    }

    const buscarFondoDeSeccionTarjetaByPosicionIndex = (indice) => {
        let modeloFondoTarjeta = {
            idFondoTarjeta: '',              // Primary Key
            idModeloTarjeta: '',             // Nombre de la tarjeta a la que pertenece
            descripcion: '',                 // Descripcion - función de la imagen (portada, datos, cierre, etc..)
            fondoTarjetaConMarcaDeAgua: '',  // imagenBase64 con marca de aguar
            fondoTarjetaSinMarcaDeAgua: '',  // imagenBase64 sin marca de agua
        }

        if (fondosDePantallasTarjeta.length > 0) {
            modeloFondoTarjeta = fondosDePantallasTarjeta[indice];
            //console.log(modeloFondoTarjeta);
            if (isEdicionFinalizada === '0') {
                return modeloFondoTarjeta.fondoTarjetaConMarcaDeAgua;
            } else {
                return modeloFondoTarjeta.fondoTarjetaSinMarcaDeAgua;
            }
        }

        return '';
    }

    const pantallaTarjeta = (
        <Fragment>
            <div className="d-flex flex-row pt-0 justify-content-center align-items-center flex-wrap">
                {
                    seccionesTarjetaUsuario.map((seccionTarjetaUsuario, indice) => (
                        <Fragment key={seccionTarjetaUsuario.idTarjetaUsuarioSeccion}>
                            {/* <!-- FlexBox --> */}
                            <div className="contenedorSeccionTarjeta">
                                {/* <!-- Card --> */}
                                <div className="seccionTarjeta" key={seccionTarjetaUsuario.idTarjetaUsuarioSeccion}>
                                    <Escritorio codigoIngresado={codigoIngresado} seccionTarjetaUsuario={seccionTarjetaUsuario} indice={indice} fondoSeccionTarjeta={buscarFondoDeSeccionTarjetaByPosicionIndex(indice)} />
                                    {/* <Escritorio codigoIngresado={codigoIngresado} seccionTarjetaUsuario={seccionTarjetaUsuario} fondoSeccionTarjeta={buscarFondoDeSeccionTarjetaById(seccionTarjetaUsuario.idFondoTarjeta)}/>  */}
                                </div>
                            </div>
                        </Fragment>
                    )
                    )
                }
            </div>
        </Fragment>
    );

    const pantallaMobile = (
        <Fragment>
            {
                seccionesTarjetaUsuario.map((seccionTarjetaUsuario, indice) => (
                    <Fragment key={seccionTarjetaUsuario.idTarjetaUsuarioSeccion}>
                        {indice === 0 ?
                            <MobileInicioPantalla codigoIngresado={codigoIngresado} seccionTarjetaUsuario={seccionTarjetaUsuario} indice={indice} fondoSeccionTarjeta={buscarFondoDeSeccionTarjetaByPosicionIndex(indice)} colorFlechaDeslizar={colorFlechaDeslizar} cantidadDeSeccionesTU={seccionesTarjetaUsuario.length}/>
                            :
                            <Mobile codigoIngresado={codigoIngresado} seccionTarjetaUsuario={seccionTarjetaUsuario} indice={indice} fondoSeccionTarjeta={buscarFondoDeSeccionTarjetaByPosicionIndex(indice)} />
                        }

                        {/* <Mobile codigoIngresado={codigoIngresado} seccionTarjetaUsuario={seccionTarjetaUsuario} fondoSeccionTarjeta={buscarFondoDeSeccionTarjetaById(seccionTarjetaUsuario.idFondoTarjeta)}/>  */}
                    </Fragment>
                    )
                )
            }
            {/* Agrega una separación en el fin de la tarjeta para la barra de abajo en los iphone */}
            <div id="agregadoPieTarjetaParaIphone"></div>
        </Fragment>
    );

    // set the loop of audio tune
    useEffect(() => {
        if (audioNombre.length > 0) {
            audioTune.loop = playInLoop;
        }
    }, [playInLoop])

    // play audio sound
    const playSound = () => {
        if (audioNombre.length > 0) {
            audioTune.load();
            audioTune.loop = playInLoop;
            audioTune.volume = 0.5;
            audioTune.play();

        }
        setMostrarPantallaLogin(false);
    }

    const pantallaLogin = (
        <Fragment>
            <div id="LoginPersonalizado" onClick={playSound} >
                <h1 className='t0' content="notranslate">   </h1>
                {query.get("t1") === null ? "" : <h1 className='t1' content="notranslate"> {query.get('t1')} </h1>}
                {query.get("t2") === null ? "" : <h1 className='t2' content="notranslate"> {query.get('t2')} </h1>}
                {query.get("t3") === null ? "" : <h1 className='t3' content="notranslate"> {query.get('t3')} </h1>}
                {tarjetaUsuario.loginCampo1 === null ? "" : <center><h1 className='t4' content="notranslate"> {tarjetaUsuario.loginCampo1}</h1></center>}
                <img id="swipeUp" src="./assets/img/PantallaClickPersonalizado.svg" alt=""></img>
                {tarjetaUsuario.loginCampo2 === null ? "" : <h1 className='t5' content="notranslate"> {tarjetaUsuario.loginCampo2} </h1>}
                {tarjetaUsuario.loginCampo3 === null ? "" : <h1 className='t6' content="notranslate"> {tarjetaUsuario.loginCampo3} </h1>}
            </div>
        </Fragment>
    )

    return (
        <Fragment>
            {isLoading ? <Loading />
                :
                mostrarPantallaLogin ? pantallaLogin : ((anchoPantalla > 500) ? pantallaTarjeta : pantallaMobile)
            }
            <ToastContainer />
        </Fragment>
    );
}

export default Home;
