import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function ModalConfirmacion({ titulo, texto, mostrarModal, setMostrarModal, eliminarOperacion }) {

    const handleClose = () => setMostrarModal(false);

    const handleConfirmacion = () => {
        setMostrarModal(false);
        eliminarOperacion();
    }

    return (
        <>
            <Modal show={mostrarModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{titulo}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {texto}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={handleConfirmacion}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ModalConfirmacion;