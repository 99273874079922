import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
  
export const showSuccessToast = (texto) => {
    return toast.success(texto, {
        closeButton: true,
        progressAnimation: 'decreasing',
        positionClass: 'toast-top-right'
    });
}
  
export const showErrorToast = (texto) => {
    toast.error(texto, {
        closeButton: true,
        progressAnimation: 'decreasing',
        positionClass: 'toast-top-right'
    });
}
  
export const showWarningToast = (texto) => {
    toast.warning(texto, {
        closeButton: true,
        progressAnimation: 'decreasing',
        positionClass: 'toast-top-right'
    });
}
  
export const showInfoToast = (texto) => {
    toast.info(texto, {
        closeButton: true,
        progressAnimation: 'decreasing',
        positionClass: 'toast-top-right'
    });
}
