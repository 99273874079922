import {create} from "zustand";
import { mountStoreDevtool } from 'simple-zustand-devtools';

export const useStoreModella = create((set, get) => ({
    codigoTarjeta: '',
    datosTarjetaUsuarioSeccion: '', // datos de la seccion de una tarjeta seleccionada para edicion
    datosFondoTarjeta: '', // fondo de la sección seleccionada para edicion
    edicionFinalizada: '1', // 0: Editable - 1: No editable
    reset: '0', // 0: json usuario - 1: json template
    updateCodigoTarjeta: (newValor) => {
        const codigoTarjeta = get().codigoTarjeta;
        set({codigoTarjeta: newValor})
    },
    updateDatosTarjetaUsuarioSeccion: (newValor) => {
        const datosTarjetaUsuarioSeccion = get().datosTarjetaUsuarioSeccion;
        set({datosTarjetaUsuarioSeccion: newValor})
    },
    updateDatosFondoTarjeta: (newValor) => {
        const datosFondoTarjeta = get().datosFondoTarjeta;
        set({datosFondoTarjeta: newValor})
    },
    updateEdicionFinalizada: (newValor) => {
        const edicionFinalizada = get().edicionFinalizada;
        set({edicionFinalizada: newValor})
    },
    updateReset: (newValor) => {
        const reset = get().reset;
        set({reset: newValor})
    },
    removeDatosTarjetaUsuarioSeccion: () => set({datosTarjetaUsuarioSeccion: ''}, false),
    removeDatosFondoTarjeta: () => set({datosFondoTarjeta: ''}, false),
    removeEdicionFinalizada: () => set({datosEdicionFinalizada: 1}, false),
    removeReset: () => set({reset: 0}, false),
}));

if(process.env.REACT_APP_ENTORNO === "DESA"){
    mountStoreDevtool('useStoreModella', useStoreModella);
}
