import { fabric } from 'fabric'
import lottie from 'lottie-web'

fabric.LinkLottieAC = fabric.util.createClass(fabric.Image, {
  type: 'linkLottieAC',
  lockRotation: false,
  lockSkewingX: false,
  lockSkewingY: false,
  srcFromAttribute: false,

  initialize: function (path, options) {
    if (options.width === undefined) options.width = 1200
    if (options.height === undefined) options.height = 1200
    if (options.dato_ac === undefined) options.dato_ac = "http://www.acolores.es"
    if (options.bloqueado_ac === undefined) options.bloqueado_ac = false
    if (options.loop_ac === undefined) options.loop_ac = true
    if (options.autoplay_ac === undefined) options.autoplay_ac = true
    if (options.ratio_ac === undefined) options.ratio_ac = 75

    //this.path = path
    this.path = path
    this.tmpCanvasEl = fabric.util.createCanvasElement()
    this.tmpCanvasEl.width = options.width
    this.tmpCanvasEl.height = options.height

    this.dato_ac = options.dato_ac
    this.bloqueado_ac = options.bloqueado_ac
    this.loop_ac = options.loop_ac
    this.autoplay_ac = options.autoplay_ac
    this.ratio_ac = options.ratio_ac

    this.lottieItem = lottie.loadAnimation({
      renderer: 'canvas',
      loop: this.loop_ac,
      autoplay: this.autoplay_ac,
      path,
      rendererSettings: {
        context: this.tmpCanvasEl.getContext('2d'),
        preserveAspectRatio: 'xMidYMid meet',
      },
    })

    // this.lottieItem.addEventListener('DOMLoaded', () => {
    //   console.log('DOMLoaded')
    // })

    this.lottieItem.addEventListener('enterFrame', (e) => {
      this.canvas?.requestRenderAll()
    })

    this.callSuper('initialize', this.tmpCanvasEl, options)

    options && this.set('dato_ac', options.dato_ac);
    options && this.set('bloqueado_ac', options.bloqueado_ac);
    options && this.set('loop_ac', options.loop_ac);
    options && this.set('autoplay_ac', options.autoplay_ac);
    options && this.set('ratio_ac', options.ratio_ac);  
  },

  toObject: function() {
    return fabric.util.object.extend(this.callSuper('toObject'), 
      { 
        dato_ac: this.dato_ac,
        bloqueado_ac: this.bloqueado_ac,
        loop_ac: this.loop_ac, 
        ratio_ac: this.ratio_ac, 
        autoplay_ac: this.autoplay_ac}
    );
  },

  play: function () {
    this.lottieItem.play()
  },
  pause: function () {
    this.lottieItem.pause()
  },  
  stop: function () {
    this.lottieItem.stop()
  },
  getLoop: function () {
    return this.lottieItem.loop;
  }, 
  changeLoop: function () {
    if(this.lottieItem.loop  === true ){
      this.loop_ac = false;
      this.lottieItem.loop = false;
    } else {
      this.loop_ac = true;
      this.lottieItem.loop = true;
    }
  },
  getAutoplay: function () {
    return this.lottieItem.autoplay;
  }, 
  changeAutoplay: function () {
    if(this.lottieItem.autoplay  === true ){
      this.autoplay_ac = false;
      this.lottieItem.autoplay = false;
    } else {
      this.autoplay_ac = true;
      this.lottieItem.autoplay = true;
    }
  },
  getBloqueado: function () {
    return this.bloqueado_ac;
  }, 
  changeBloqueado: function () {
    if(this.bloqueado_ac  === true ){
      this.bloqueado_ac = false;
    } else {
      this.bloqueado_ac = true;
    }
  },
  getDato: function () {
    return this.dato_ac;
  }, 
  changeDato: function (newDato) {
    this.dato_ac = newDato;
  },
  changeRatio: function(ratio){
    this.ratio_ac = ratio;
  },
  getRatio: function () {
    return this.ratio_ac;
  }, 
  playRatio: function(ratio){
    if( this.ratio_ac <= ratio ){
      this.play();
    }
  },  
  getSrc: function () {
    return this.path
  }, 
});

fabric.LinkLottieAC.fromObject = function (_object, callback) {
  if(_object !== 'undefined'){

    const object = fabric.util.object.clone(_object);
    fabric.Image.prototype._initFilters.call(object, object.filters, function (filters) {
      object.filters = filters || []
      fabric.Image.prototype._initFilters.call(object, [object.resizeFilter], function (resizeFilters) {
        object.resizeFilter = resizeFilters[0]
        fabric.util.enlivenObjects([object.clipPath], function (enlivedProps) {
          object.clipPath = enlivedProps[0]
          const fabricLottie = new fabric.LinkLottieAC(object.src, object)
          callback(fabricLottie, false)
        })
      })
    })
  }
}

fabric.LinkLottieAC.async = true;

export default fabric.LinkLottieAC;