import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
// Muestra el componente de loadging
import Loading from '../../../Componentes/Loading/Loading';
// Toast 
import { ToastContainer } from 'react-toastify';
import { showErrorToast, showSuccessToast } from '../../../../servicios/toast/toast';

// Gestion de datos del backend invitado
import { getLoginPersonalizadoById, putLoginPersonalizado, deleteLoginPersonalizado  } from "../../../../servicios/axios/restApi/useApiLoginPersonalizado";

// Modal de confirmacion
import ModalConfirmacion from '../../../Componentes/ModalConfirmacion/ModalConfirmacion';

// Componente internacionalizacion
import { useTranslation } from 'react-i18next';

// Zustand
import { useStoreDatosPortada } from './../../../../servicios/stores/usuario/useStoreDatosPortada';

import "./EditarLoginPesonalizado.css"; 

const EditarLoginPesonalizado = () => {

    //zustand
    const loginCampo1 = useStoreDatosPortada((state) => state.loginCampo1);
    const loginCampo2 = useStoreDatosPortada((state) => state.loginCampo2);
    const loginCampo3 = useStoreDatosPortada((state) => state.loginCampo3);

    // Cambio de idioma
    const [translate, i18n] = useTranslation('global');

    // Variables de componente
    const { id, codigo, idioma } = useParams();

    const navigate = useNavigate();

    let modeloInvitado = {
        idLoginPersonalizado: '', // Primary Key
        campo1: '',
        campo2: '',
        campo3: '',
        tarjetaCodigo: '',
        idioma: '',
        urlCodigo: ''
    }

    const [datosDB, setDatosDB] = useState("");

    const [isLoading, setIsLoading] = useState(false);

    const [mostrarModal, setMostrarModal] = useState(false);

    useEffect(() => {
        setIsLoading(true);

        const cargaDatosInvitado = async () => {
            const datosDBAux = await getLoginPersonalizadoById(id, codigo);

            modeloInvitado.idLoginPersonalizado = datosDBAux[0].idLoginPersonalizado;
            modeloInvitado.campo1 = datosDBAux[0].campo1;
            modeloInvitado.campo2 = datosDBAux[0].campo2;
            modeloInvitado.campo3 = datosDBAux[0].campo3;
            modeloInvitado.tarjetaCodigo = datosDBAux[0].tarjetaCodigo;
            modeloInvitado.idioma = datosDBAux[0].idioma;
            modeloInvitado.urlCodigo = datosDBAux[0].urlCodigo;

            //console.log(modeloInvitado);

            setDatosDB(modeloInvitado);

            setIsLoading(false);
        }

        try {
            if (id > 0) {
                cargaDatosInvitado();
            } else {
                setIsLoading(false);
                showErrorToast(translate('LI_ERROR_003'));
            }

        } catch (e) {
            setIsLoading(false);
            showErrorToast(translate('LI_ERROR_001'));
        }
    }, []);

    // Funciones de operaciones
    const actualizarDatos = async (event) => {
        event.preventDefault();

        try {
            if (datosDB.campo1.length > 0) {
                setIsLoading(true);

                datosDB.campo1 = datosDB.campo1.replace(/'/g, "''");
                datosDB.campo2 = datosDB.campo2.replace(/'/g, "''");
                datosDB.campo3 = datosDB.campo3.replace(/'/g, "''");

                const respuesta = await putLoginPersonalizado(datosDB);

                if (respuesta.status === 'ok') {
                    showSuccessToast(translate('LP_UPDATE_001'));
                } else {
                    setIsLoading(false);
                    showErrorToast(translate('LP_UPDATE_002'));
                }
                setIsLoading(false);
                volver();
            } else {
                if (datosDB.campo1.length === 0) showErrorToast(translate('LP_AGREGAR_003'));
            }

        } catch (e) {
            setIsLoading(false);
            showErrorToast(translate('LI_ERROR_001'));
        }
    }

    const openModelConfirmacion = () => {
        setMostrarModal(true)
    }

    const eliminarOperacion = async () => {
        setIsLoading(true);

        try {
            const respuesta = await deleteLoginPersonalizado(datosDB.idLoginPersonalizado, codigo);

            if (respuesta.status === 'ok') {
                showSuccessToast(translate('LP_DELETE_001'));
            } else {
                showErrorToast(translate('LP_DELETE_002'));
            }

            setIsLoading(false);
            volver();
        } catch (e) {
            setIsLoading(false);
            showErrorToast(translate('LI_ERROR_001'));
        }
    }

    const volver = () => {
        navigate("/LP?codigo=" + codigo + '&idioma=' + idioma);
    }

    // Muestra el componente de carga cuando se hace una operación en contra la BD
    // funciones de campos
    const handleInputChange = event => {
        const { name, value } = event.target
        setDatosDB({ ...datosDB, [name]: value })
    }

    const handleCheckBoxChange = event => {
        const { name, checked } = event.target
        setDatosDB({ ...datosDB, [name]: (checked ? '1' : '0') })
    }

    const handleRadioButtonChange = event => {
        const { name, value } = event.target
        setDatosDB({ ...datosDB, [name]: value })
    }

    
    // funciones de pantallas
    const pantallaLoginPersonalizado = (
        <div className="container-fluid bg-white text-white pt-2 pb-2 text-center">
            <div className="row">
                <div className="col-12">
                    <h1 style={{ color: "black", fontSize: "1.1rem" }}>{translate('LP_TABLE_TITULO_EDITAR')}</h1>
                </div>
            </div>
            <div className="row">
                <div className="col-1">
                </div>
                <div className="col-10">
                    <div className="row pt-1">
                        <div className="col-6" style={{backgroundColor:'transparent'}}>
                            <form onSubmit={actualizarDatos}>
                                <br />

                                <button type="submit" className="btn btn-primary btn-sm" >{translate('LI_EDITAR_ACTUALIZAR')}</button>&nbsp;
                                <button type="button" className="btn btn-danger btn-sm" onClick={openModelConfirmacion}>{translate('LI_EDITAR_ELIMINAR')}</button>&nbsp;
                                <button type="button" className="btn btn-dark btn-sm" onClick={volver}>{translate('LI_EDITAR_VOLVER')}</button>

                                <br /><br />

                                <div className="row">
                                    <div  className="col-3"></div>
                                    <div  className="col-6" style={{textAlign:"left"}}>
                                        <label htmlFor="campo1" style={{color:"black", fontSize:"1.1rem"}}>{translate('LP_CAMPO_1')}</label>
                                        <span>: &nbsp; </span>
                                        <input className="form-control" type="text"
                                            name="campo1" id="campo1"
                                            onChange={handleInputChange} value={datosDB.campo1}
                                        />
                                    </div><div  className="col-3"></div>
                                </div> 

                                <div className="row">
                                    <div  className="col-3"></div>
                                    <div  className="col-6" style={{textAlign:"left"}}>
                                        <label htmlFor="campo2" style={{color:"black", fontSize:"1.1rem"}}>{translate('LP_CAMPO_2')}</label>
                                        <span>: &nbsp; </span>
                                        <input className="form-control" type="text"
                                            name="campo2" id="campo2"
                                            onChange={handleInputChange} value={datosDB.campo2}
                                        />
                                    </div><div  className="col-3"></div>
                                </div>                        

                                <div className="row">
                                    <div  className="col-3"></div>
                                    <div  className="col-6" style={{textAlign:"left"}}>
                                        <label htmlFor="campo3" style={{color:"black", fontSize:"1.1rem"}}>{translate('LP_CAMPO_3')}</label>
                                        <span>: &nbsp; </span>
                                        <input className="form-control" type="text"
                                            name="campo3" id="campo3"
                                            onChange={handleInputChange} value={datosDB.campo3}
                                        />
                                    </div><div  className="col-3"></div>
                                </div>  

                                <div className="row">
                                    <div  className="col-3"></div>
                                    <div  className="col-6" style={{textAlign:"left"}}>
                                        <label htmlFor="tarjetaCodigo" style={{color:"black", fontSize:"1.1rem"}}>{translate('LP_CAMPO_CODIGO')}</label>
                                        <span>: &nbsp; </span>
                                        <input className="form-control" type="text" disabled
                                            name="tarjetaCodigo" id="tarjetaCodigo"
                                            onChange={handleInputChange} value={datosDB.tarjetaCodigo}
                                        />
                                    </div><div  className="col-3"></div>
                                </div>  

                                <div className="row">
                                    <div  className="col-3"></div>
                                    <div  className="col-6" style={{textAlign:"left"}}>
                                        <label htmlFor="idioma" style={{color:"black", fontSize:"1.1rem"}}>{translate('LP_CAMPO_IDIOMA')}</label>
                                        <span>: &nbsp; </span>
                                        <input className="form-control" type="text" disabled
                                            name="idioma" id="idioma"
                                            onChange={handleInputChange} value={datosDB.idioma}
                                        />
                                    </div><div  className="col-3"></div>
                                </div>                          

                                <div className="row">
                                    <div  className="col-3"></div>
                                    <div  className="col-6" style={{textAlign:"left"}}>
                                        <label htmlFor="urlCodigo" style={{color:"black", fontSize:"1.1rem"}}>{translate('LP_CAMPO_URL_CODE')}</label>
                                        <span>: &nbsp; </span>
                                        <input className="form-control" type="text" disabled
                                            name="urlCodigo" id="urlCodigo" 
                                            onChange={handleInputChange} value={datosDB.urlCodigo}
                                        />
                                    </div><div  className="col-3"></div>
                                </div> 

                            </form>
                        </div>    
                        <div className="col-6" style={{backgroundColor:'transparent'}}>
                            <div className="card" id="LoginPersonalizado" style={{ border: '1px dashed lightslategray', width: '23rem', height: '40rem', backgroundColor: 'whitesmoke', position:'relative'}}>
                                <div className="card-body">
                                    <label htmlFor="" style={{paddingTop:'0.1rem'}}>&nbsp;</label>
                                    <h1 className='t1' style={{color:'black'}} content="notranslate"> {datosDB.campo1} </h1>
                                    <h1 className='t2' style={{color:'black'}} content="notranslate"> {datosDB.campo2} </h1>
                                    <h1 className='t3' style={{color:'black'}} content="notranslate"> {datosDB.campo3} </h1>
                                    <center><h1 style={{color:'black'}} className='t4' content="notranslate"> {loginCampo1} </h1></center> 
                                    <img id="swipeUp" src="./assets/img/PantallaClickPersonalizado.svg" alt=""></img>
                                    <h1 style={{color:'black'}} className='t5' content="notranslate"> {loginCampo2} </h1>
                                    <h1 style={{color:'black'}} className='t6' content="notranslate"> {loginCampo3} </h1> 
                                </div>
                            </div>   
                        </div>            
                    </div>   
                </div>
                <div className="col-1">
                </div>
            </div>
        </div>
    );

    const pantallaLoginPersonalizadoNoEncontrado = (
        <div className="container-fluid bg-white text-white pt-2 pb-2 text-center">
            <div className="row">
                <div className="col-1">
                </div>
                <div className="col-10">
                    <div>
                        <br />
                        <button type="button" className="btn btn-dark btn-sm" onClick={volver}>{translate('LI_EDITAR_VOLVER')}</button>
                        <br />
                    </div>
                    <div>
                        <p style={{ 'color': 'black' }}>{translate('LI_EDITAR_NO_HAY_DATOS')}</p>
                    </div>
                </div>
                <div className="col-1">
                </div>
            </div>
        </div>
    );

    return (
        <>
            {isLoading ? <Loading /> : ""}
            {datosDB ? pantallaLoginPersonalizado : pantallaLoginPersonalizadoNoEncontrado}
            {mostrarModal ? <ModalConfirmacion titulo={translate('LI_EDITAR_MODAL_TITULO')} texto={translate('LI_EDITAR_MODAL_DESCRIPCION')} mostrarModal={mostrarModal} setMostrarModal={setMostrarModal} eliminarOperacion={eliminarOperacion} /> : ""}
            <ToastContainer />
        </>
    )
}

export default EditarLoginPesonalizado;