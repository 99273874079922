import "./Loading.css";

// Componente internacionalizacion
import { useTranslation } from 'react-i18next';

function Loading() {

  const [translate, i18n] = useTranslation('global');

  return (
    <div className="contenedorLoading">
        <br/>
        <div className="contenedor_loader">
            <div className="loader"></div>
        </div>
        <br/>
        <p className="textoLoading">{translate('LOADING')}</p>
    </div>
  )
}

export default Loading
