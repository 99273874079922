import React, { useEffect, useState, useRef } from 'react';
import lottie from 'lottie-web';

const LottieAnimationSinCanva = ({pathAnimacionFondo, anchoCanvas, altoCanvas}) => {

    const [lottieCargado, setLottieCargado] = useState(false);

    const animacionContenedorRef = useRef(null);

    useEffect(() => {
        // Referencia al contenedor donde se renderizará la animación
        const container = document.getElementById('lottie-container');

        // Configuración de la animación
        const animation = lottie.loadAnimation({
            container: animacionContenedorRef.current,
            renderer: 'svg', // Utilizamos el renderizador SVG para mayor compatibilidad // "svg", "canvas", "html"
            path: pathAnimacionFondo,
            loop: true, // Indica si la animación se debe repetir en bucle
            autoplay: true, // Iniciar la animación automáticamente cuando se carga
            rendererSettings: {
//                preserveAspectRatio: 'xMidYMid meet',
            },
        });

        // Alinear al centro de la pantalla
        container.style.display = 'flex';
        container.style.justifyContent = 'center';
        container.style.alignItems = 'center';

        setLottieCargado(true);

        // Limpia la animación cuando el componente se desmonta
        return () => {
            animation.destroy();
        };
    }, []);

  return <div ref={animacionContenedorRef} id="lottie-container" style={{ position: 'absolute', width: anchoCanvas + 'px', height: altoCanvas + 'px', zIndex: '0' }} />;

};

export default LottieAnimationSinCanva;
