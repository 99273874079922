import { useEffect, useMemo, useState } from "react";

// Componente internacionalizacion
import { useTranslation } from 'react-i18next';

import "./Timer.css"

const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

export const Timer = ({ deadline = new Date().toString(), coloTexto = '#000000' }) => {

    // Cambio de idioma
    const [translate, i18n] = useTranslation('global');

    const parsedDeadline = useMemo(() => Date.parse(deadline), [deadline]);
    const [time, setTime] = useState(parsedDeadline - Date.now());

    useEffect(() => {
        const interval = setInterval(
            () => setTime(parsedDeadline - Date.now()),
            1000,
        );

        return () => clearInterval(interval);
    }, [parsedDeadline]);

    if( time > 0 ){
        const textoDias = translate('CONTADOR_TEXTO_DIAS');
        if(i18n.language === 'es'){
            return (
                <div className="timer">
                    {Object.entries({
                        Dias: time / DAY,
                        Horas: (time / HOUR) % 24,
                        Min: (time / MINUTE) % 60,
                        Seg: (time / SECOND) % 60,
                    }).map(([label, value]) => (
                        <div key={label} className="col">
                            <div className="box">
                                <p style={{color:`${coloTexto}`}}>{`${Math.floor(value)}`.padStart(2, "0")}</p>
                                <span className="text" style={{color:`${coloTexto}`}}>{label}</span>
                            </div>
                        </div>
                    ))}
                </div>
            );
        }else{
            return (
                <div className="timer">
                    {Object.entries({
                        Days: time / DAY,
                        Hours: (time / HOUR) % 24,
                        Min: (time / MINUTE) % 60,
                        Sec: (time / SECOND) % 60,
                    }).map(([label, value]) => (
                        <div key={label} className="col">
                            <div className="box">
                                <p style={{color:`${coloTexto}`}}>{`${Math.floor(value)}`.padStart(2, "0")}</p>
                                <span className="text" style={{color:`${coloTexto}`}}>{label}</span>
                            </div>
                        </div>
                    ))}
                </div>
            );
        }        
    }else{
        return (
            <div className="timerFinalizado">
                <p style={{color:`${coloTexto}`}}> {translate('CONTADOR_TEXTO_FINALIZADO')}</p>
            </div>       
        );
    }
};

export default Timer