/* eslint-disable default-case */
import React, { useEffect, useRef, useState, Fragment } from 'react'
import { useNavigate } from "react-router-dom";

// Muestra el componente de loadging
import Loading from './../../../Componentes/Loading/Loading';

// Toast 
import { ToastContainer } from 'react-toastify';
import { showErrorToast, showSuccessToast } from '../../../../servicios/toast/toast';

// Librearia Fabric
import { fabric } from 'fabric';

// Zustand Store para ocultar el Header
import { useStoreModella } from './../../../../servicios/stores/modella/useStoreModella';

// Componente internacionalizacion
import { useTranslation } from 'react-i18next';

// Copy to clipboard
import copy from "copy-to-clipboard";

// Contador de la tarjeta
import { Timer } from './../../../Componentes/Countdown/Timer';

// ********************* Lottie *************************
import lottie from 'lottie-web'

import LottieAC from '../../../Componentes/Lottie/LottieAC';
import LottieFlotanteAC from '../../../Componentes/Lottie/LottieFlotanteAC';
import LinkLottieAC from '../../../Componentes/Lottie/LinkLottieAC';
import CopyLottieAC from '../../../Componentes/Lottie/CopyLottieAC';
import ConfirmarAsistenciaLottieAC from '../../../Componentes/Lottie/ConfirmarAsistenciaLottieAC';
import ContadorLottieAC from '../../../Componentes/Lottie/ContadorLottieAC';

// Crea animaciones lottie puras sin canva.
import LottieAnimationSinCanva from '../../../Componentes/Lottie/LottieAnimationSinCanva';

// Componente de confirmacion de asistencia
import ConfirmarAsistencia from '../../../Componentes/ConfirmarAsistencia/ConfirmarAsistencia';

// Estilo de pagina
import "./Mobile.css"


const Mobile = ({ codigoIngresado, seccionTarjetaUsuario, indice, fondoSeccionTarjeta = '' }) => {

    // BEGIN - Variables de altura de pantalla - utilizadas con el OBSERVER OBJECT
    const [altura, setAltura] = useState(0)
    // inside component:
    const [scrollPosition, setScrollPosition] = useState(0);
    // ratio calulo 0..100 para disparar animaciones
    const [ratioAux, setRatioAux] = useState(0);
    // Animacion en el fondo en loop
    const [pathAnimacionFondo, setPathAnimacionFondo] = useState("");
    const [animacionFondo, setAnimacionFondo] = useState(false);

    useEffect(() => {
        setAltura(window.innerHeight)
    }, [altura])


    const handleScroll = () => {
        const position = window.scrollY;
        setScrollPosition(position);
        //console.log(position);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // END - Variables de altura de pantalla - utilizadas con el OBSERVER OBJECT

    // Variables del contexto con zustand para acceder a los datos de la sección del template tarjeta
    const edicionFinalizadaStore = useStoreModella((state) => state.edicionFinalizada);

    // Indica si el canva esta visible en pantalla
    const [isVisible, setIsVisible] = useState(false);

    // Indica si el canva esta visible en pantalla
    const [observerRatio_VisibleByEvento, setObserverRatio_VisibleByEvento] = useState(0);

    // Variables de componente
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);

    // Cambio de idioma
    const [translate, i18n] = useTranslation('global');

    const canvasRef = useRef(null);

    // Variable utilizada para determinar si el canva a sido cargado por primera vez desde un json.
    const [isCanvaCargado, setIsCanvaCargado] = useState(false);

    // Indica si los objetos del canva se han ajustado en tamaño y posición en el metodo resetScalaCanvaEdicionFinalizada
    const [isCanvaAjustado, setIsCanvaAjustado] = useState(false);

    let propiedadesObjetoInit = {
        canvasFill: '#ffffff',
        canvasImage: '',
        id: null,
        opacity: null,
        fill: null,
        fontSize: null,
        lineHeight: null,
        charSpacing: null,
        fontWeight: null,
        fontStyle: null,
        textAlign: null,
        fontFamily: null,
        TextDecoration: ''
    };

    const [forzarReaload, setForzarReaload] = useState(false);

    // Variable de estado general
    const [propiedadesObjeto, setPropiedadesObjeto] = useState(propiedadesObjetoInit);
    // Variblea que guarda el canva
    const [canvas, setCanvas] = useState(null);
    // Registra el ancho y alto del CANVAS
    const [anchoCanvas, setAnchoCanvas] = useState(200); // Define el ancho del canvas ()
    const [altoCanvas, setAltoCanvas] = useState(200); // Define el alto del canvas
    const [altoImagenFondo, setAltoImagenFondo] = useState(2208); // Alto de la imagen que irá en el fondo
    const [anchoImagenFondo, setAnchoImagenFondo] = useState(1242); // Ancho de la imagen que irá en el fondo
    const [porcentajeDeReduccion, setPorcentajeDeReduccion] = useState(1);    // % de reducción de la imagen para que entre en la pantalla

    // Variable para abir modal confirmacion de asistencia
    const [abrirModalConfirmacion, setAbrirModalConfirmacion] = useState(false);

    // Indica si el usuario se puedo registrar
    const [isUsuarioRegistrado, setIsUsuarioRegistrado] = useState(false);

    // Variables atributos de un objeto lottie
    const [scalaObjetoLottie, setScalaObjetoLottie] = useState(1);
    const [typeObjetoLottie, setTypeObjetoLottie] = useState('');
    const [urlObjetoLottie, setUrlObjetoLottie] = useState('');
    const [loopObjetoLottie, setLoopObjetoLottie] = useState(false);
    const [autoplayObjetoLottie, setAutoplayObjetoLottie] = useState(false);
    const [bloqueadoObjetoLottie, setBloqueadoObjetoLottie] = useState(false);
    const [datoObjetoLottie, setDatoObjetoLottie] = useState('');

    let TemplatepropiedadesDiv = {
        id: '',
        top: 0,
        left: 0,
        width: 0,
        height: 0,
        dato_ac: '',
        posicion_ac: ''
    };

    useEffect(() => {
        const anchoCanvasAux = window.innerWidth;
        const porcentajeDeReduccion = (anchoCanvasAux * 100) / seccionTarjetaUsuario.anchoImagen;
        const altoCanvasAux = (porcentajeDeReduccion * seccionTarjetaUsuario.altoImagen) / 100;

        setAltoImagenFondo(seccionTarjetaUsuario.altoImagen);
        setAnchoImagenFondo(seccionTarjetaUsuario.anchoImagen);

        initCanvas(altoCanvasAux, anchoCanvasAux);
        setAltoCanvas(altoCanvasAux);
        setAnchoCanvas(anchoCanvasAux);
    }, []);

    const initCanvas = (altoCanvasAux, anchoCanvasAux) => {
        //const c = new fabric.StaticCanvas(canvasRef.current, {
        const c = new fabric.StaticCanvas(canvasRef.current, {
            height: altoCanvasAux,
            width: anchoCanvasAux,
            //hoverCursor: 'pointer',
            //selectionBorderColor: 'blue'
        });
        setCanvas(c);
    };

    // BEGIN OBSERVER - Valida que el canvas este visible  

    useEffect(() => {
        const buildThresholdList = () => {
            let thresholds = [];
            let numSteps = 20;

            thresholds.push(0);

            for (let i = 1.0; i <= numSteps; i++) {
                let ratio = i / numSteps;
                thresholds.push(ratio);
            }
            
            return thresholds;
        }

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                // Indica cuando el canva se visualiza en la pantalla
                setIsVisible(entry.isIntersecting);
                // Se actualiza con cada valor del (threshold)
                setObserverRatio_VisibleByEvento(entry.intersectionRatio)
            });
        },
            {
                //root: null,
                //rootMargin: '0px',
                threshold: buildThresholdList(),
            }
        );

        observer.observe(canvasRef.current);

        return () => observer.unobserve(canvasRef.current);
    }, []);

    useEffect(() => {
        if (canvas) {
            if (!isCanvaCargado && (indice === 1 || indice === 2)) {
                setFondoDesdeZustand();
                //console.log("load fondo canva")
                setIsCanvaCargado(!isCanvaCargado);
            }
        }    

        if (isVisible) {
            // Renderizar las animaciones del canvas
            if (canvas) {
                if (!isCanvaCargado) {
                    setFondoDesdeZustand();
                    //console.log("load fondo canva")
                    setIsCanvaCargado(!isCanvaCargado);
                }
                // Ejecuta las animaciones con AUTOPLAY === TRUE
                playAniamaciones();

                let scrollPositionAjustadoPorIndice = 0;

                if (indice === 0) {
                    scrollPositionAjustadoPorIndice = scrollPosition - (altura * (indice));
                    setRatioAux((scrollPositionAjustadoPorIndice * 100) / altura);
                    //console.log("PLAY seccion: " + seccionTarjetaUsuario.numeroDeSeccionTarjetaUsuario + " - Ratio: " + Math.round(ratioAux) + " - indice: " + indice + " - altura: " + altura + " - scrollPositionAjustadoPorIndice: " + scrollPositionAjustadoPorIndice + " scrollPosition: " + scrollPosition);
                }

                if (indice === 1 && ratioAux <= 101) {
                    if (scrollPositionAjustadoPorIndice < altura) {
                        scrollPositionAjustadoPorIndice = scrollPosition;
                        setRatioAux((scrollPositionAjustadoPorIndice * 100) / altura);
                        //console.log("PLAY seccion: " + seccionTarjetaUsuario.numeroDeSeccionTarjetaUsuario + " - Ratio: " + Math.round(ratioAux) + " - indice: " + indice + " - altura: " + altura + " - scrollPositionAjustadoPorIndice: " + scrollPositionAjustadoPorIndice + " scrollPosition: " + scrollPosition);
                    } else {
                        scrollPositionAjustadoPorIndice = scrollPosition - (altura * (indice));
                        setRatioAux((scrollPositionAjustadoPorIndice * 100) / altura);
                        //console.log("PLAY seccion: " + seccionTarjetaUsuario.numeroDeSeccionTarjetaUsuario + " - Ratio: " + Math.round(ratioAux) + " - indice: " + indice + " - altura: " + altura + " - scrollPositionAjustadoPorIndice: " + scrollPositionAjustadoPorIndice + " scrollPosition: " + scrollPosition);
                    }
                }

                if (indice > 1 && ratioAux <= 101) {
                    scrollPositionAjustadoPorIndice = scrollPosition - (altura * (indice - 1));
                    setRatioAux((scrollPositionAjustadoPorIndice * 100) / altura);
                    //console.log("PLAY seccion: " + seccionTarjetaUsuario.numeroDeSeccionTarjetaUsuario + " - Ratio: " + Math.round(ratioAux) + " - indice: " + indice + " - altura: " + altura + " - scrollPositionAjustadoPorIndice: " + scrollPositionAjustadoPorIndice + " scrollPosition: " + scrollPosition + " indice > 1: " + (indice > 1) + " ratio < 101: " + (ratioAux <= 101));
                }

                if (ratioAux >= 0 && ratioAux < 100) {
                    //console.log("PLAY seccion: " + seccionTarjetaUsuario.numeroDeSeccionTarjetaUsuario + " - Ratio: " + Math.round(ratioAux) + " - indice: " + indice + " - altura: " + altura + " - scrollPositionAjustadoPorIndice: " + scrollPositionAjustadoPorIndice + " scrollPosition: " + scrollPosition);
                    playAniamacionesByEvento(Math.round(ratioAux));
                }

            }
        } else {
            // NO Renderizar las animcaciones del canvas
            if (canvas) {
                pausarAniamaciones();
                //console.log("STOP seccion: " + seccionTarjetaUsuario.numeroDeSeccionTarjetaUsuario + " - Ratio: " + observerRatio_VisibleByEvento + " - Visible: " + isVisible);
            }
        }
    }, [observerRatio_VisibleByEvento, isVisible, canvas]);

    // END OBSERVER - Valida que el canvas este visible 

    const setFondoDesdeZustand = () => {
        if (canvas !== null && fondoSeccionTarjeta.length > 0 && seccionTarjetaUsuario.idTarjetaUsuarioSeccion !== undefined) {
            setAltoImagenFondo(seccionTarjetaUsuario.altoImagen);
            setAnchoImagenFondo(seccionTarjetaUsuario.anchoImagen);

            let jsonFromBaseDeDatosAux = seccionTarjetaUsuario.jsonSeccionTarjetaUsuario;

            loadCanvasFromJSON_BaseDeDatos(jsonFromBaseDeDatosAux.substring(1, jsonFromBaseDeDatosAux.length - 1),
                seccionTarjetaUsuario.anchoCanvas,
                seccionTarjetaUsuario.altoCanvas,
                seccionTarjetaUsuario.anchoImagen,
                seccionTarjetaUsuario.altoImagen,
                fondoSeccionTarjeta
            );

            canvas.requestRenderAll.bind(canvas);
            //pausarAniamaciones();
        }
    }

    // END OBSERVER - Valida que el canvas este visible  

    /*------------------------END Block elements------------------------*/

    /**********************************************************/
    /*                      BEGIN LOTTIE                      */

    // Metodo que lanza (PLAY) las animcaciones que son AUTOMATICAS (AUTOPLAY)
    const playAniamaciones = () => {
        var objects = canvas.getObjects();
        for (var i in objects) {
            if (objects[i].autoplay_ac === true) {
                switch (objects[i].type) {
                    case 'lottieAC':
                        objects[i].play()
                        break;
                    case 'lottieFlotanteAC':
                        objects[i].play()
                        break;
                    case 'linkLottieAC':
                        //objects[i].play()
                        break;
                    case 'copyLottieAC':
                        //objects[i].play()
                        break;
                    case 'confirmarAsistenciaLottieAC':
                        //objects[i].play()
                        break;
                    case 'contadorLottieAC':
                        //objects[i].play()
                        break;
                }
            }
        }
    }

    // Metodo que lanza (PLAY) las animaciones NO automaticas
    const playAniamacionesByEvento = (ratio) => {
        var objects = canvas.getObjects();
        for (var i in objects) {
            if (objects[i].autoplay_ac === false) {
                switch (objects[i].type) {
                    case 'lottieAC':
                        objects[i].playRatio(ratio)
                        break;
                    case 'lottieFlotanteAC':
                        objects[i].playRatio(ratio)
                        break;
                    case 'linkLottieAC':
                        //objects[i].playRatio(ratio)
                        break;
                    case 'copyLottieAC':
                        //objects[i].playRatio(ratio)
                        break;
                    case 'confirmarAsistenciaLottieAC':
                        //objects[i].playRatio(ratio)
                        break;
                    case 'contadorLottieAC':
                        //objects[i].playRatio(ratio)
                        break;
                }
            }
        }
    }

    const pausarAniamaciones = () => {
        var objects = canvas.getObjects();
        for (var i in objects) {
            if (objects[i]) {
                switch (objects[i].type) {
                    case 'lottieAC':
                        objects[i].pause() 
                        break;
                    case 'lottieFlotanteAC':
                        objects[i].pause() 
                        break;
                    case 'linkLottieAC':
                        objects[i].pause()
                        break;
                    case 'copyLottieAC':
                        objects[i].pause()
                        break;
                    case 'confirmarAsistenciaLottieAC':
                        objects[i].pause()
                        break;
                    case 'contadorLottieAC':
                        objects[i].pause()
                        break;
                }
            }
        }
    }

    const pararAniamaciones = () => {
        var objects = canvas.getObjects();
        for (var i in objects) {
            if (objects[i]) {
                switch (objects[i].type) {
                    case 'lottieAC':
                        objects[i].stop()
                        break;
                    case 'lottieFlotanteAC':
                        objects[i].stop()
                        break;
                    case 'linkLottieAC':
                        objects[i].stop()
                        break;
                    case 'copyLottieAC':
                        objects[i].stop()
                        break;
                    case 'confirmarAsistenciaLottieAC':
                        objects[i].stop()
                        break;
                    case 'contadorLottieAC':
                        objects[i].stop()
                        break;
                }
            }
        }
    }

    /*                       END LOTTIE                       */
    /**********************************************************/

    /**********************************************************/
    /*                 BEGIN BACKGROUND CANVAS                */

    // Set imagen de fondo desde un imagen en Base64
    const setFondoPantallaFromImagenBase64 = (imagenBase64, anchoPantallaBD, altoPantallaDB, anchoImagenDB, altoImagenDB) => {

        const anchoCanvasAux = window.innerWidth;
        const porcentajeDeReduccion = (anchoCanvasAux * 100) / seccionTarjetaUsuario.anchoImagen;
        const altoCanvasAux = (porcentajeDeReduccion * seccionTarjetaUsuario.altoImagen) / 100;

        // When the image loads, set it as background image
        var img = new Image();

        // When the image loads, set it as background image
        img.onload = function () {
            var f_img = new fabric.Image(img);

            f_img.set({
                opacity: 1,
                scaleX: anchoCanvasAux / anchoImagenDB,
                scaleY: altoCanvasAux / altoImagenDB,

            }, { crossOrigin: 'Anonymous' });

            canvas.setBackgroundImage(f_img, canvas.requestRenderAll.bind(canvas));
        };

        // Define the Data URI
        var myDataURL = imagenBase64;
        img.src = myDataURL;
    }

    /*                 BEGIN BACKGROUND CANVAS                */
    /**********************************************************/

    /**********************************************************/
    /*           BEGIN - METODOS DE RATERIZACION              */

    // Carga el json en el canvas y define el tamaño del canvas para su correcta visualizacion
    const loadCanvasFromJSON_BaseDeDatos = (jsonFromBaseDeDatos, anchoPantallaBD, altoPantallaDB, anchoImagenDB, altoImagenDB, fondoSeccionTarjeta) => {

        // Inicia carga del json
        canvas.loadFromJSON(jsonFromBaseDeDatos, () => {
            // Define el tamaño del canvas
            if (edicionFinalizadaStore === '1') {
                resetScalaCanvaEdicionFinalizada(anchoPantallaBD, altoPantallaDB, anchoImagenDB, altoImagenDB, fondoSeccionTarjeta);
            } else {
                resetScalaCanvaEdicionFinalizada(anchoPantallaBD, altoPantallaDB, anchoImagenDB, altoImagenDB, fondoSeccionTarjeta);
            }
        });
    }

    /*           END - METODOS DE RATERIZACION              */
    /**********************************************************/

    /**********************************************************/
    /*             BEGIN - CONTROLADORES                      */

    // Copy dato a la papelera
    const copiarDatoPapelera = (datoACopiar = '') => {
        copy(datoACopiar);
        alert(datoACopiar);
    }

    // Abre una URL en una nueva pantalla o pestaña
    const abirURLEnPantallaNueva = (urlDato) => {
        let encodedURL = encodeURI(urlDato);
        window.open(encodedURL, '_self', 'noreferrer');
    };

    // IMPORTANTE SOLO USAR PARA LAS DEMOS !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    const getFechaContador = () => {
        const current = new Date();
        // it adds 15 days to a current date
        current.setDate(current.getDate() + 17);
        //console.log(current);
        let ano = current.getUTCFullYear();
        let mes = current.getMonth() + 1;
        if (mes < 10) {
            mes = "0" + mes
        }

        let dia = current.getDate();
        //console.log(current.getDate());
        if (dia < 10) {
            dia = "0" + dia
        }

        //console.log(fecha);
        const fecha = ano + "-" + mes + "-" + dia + "T12:00:00.000Z";
        return fecha;
    }

    // Genera html DIV para disparar acciones.
    const buscarControladoresLottie = () => {

        if (canvas !== null) {
            const objects = canvas.getObjects();

            const centroPantallaPx = window.innerWidth / 2;
            const centroCanvaPx = anchoCanvas / 2;
            const offsetIzquierdoCanva = centroPantallaPx - centroCanvaPx;

            const multiplicadorSeccion = seccionTarjetaUsuario.numeroDeSeccionTarjetaUsuario - 1;

            const divs = [];

            for (var i in objects) {
                switch (objects[i].type) {
                    case 'lottieAC':
                        break;
                    case 'lottieFlotanteAC':
                        break;
                    case 'linkLottieAC':
                        let propiedadesDivLinkAux = TemplatepropiedadesDiv;
                        propiedadesDivLinkAux.id = 'divAccionAC' + seccionTarjetaUsuario.numeroDeSeccionTarjetaUsuario + '_' + objects[i].id_ac;

                        propiedadesDivLinkAux.top = (canvas.height * multiplicadorSeccion) + objects[i].top;
                        propiedadesDivLinkAux.left = offsetIzquierdoCanva + objects[i].left;
                        //propiedadesDivLinkAux.left = offsetIzquierdoCanva + objects[i].left;

                        propiedadesDivLinkAux.width = objects[i].width * objects[i].scaleX;
                        propiedadesDivLinkAux.height = objects[i].height * objects[i].scaleY;

                        propiedadesDivLinkAux.dato_ac = objects[i].dato_ac;

                        const link_datos_ac_aux = objects[i].dato_ac; // Se usa una variable local ya que si existen varios div solo toma el último valor

                        divs.push(
                            <div id={'divAccionAC' + seccionTarjetaUsuario.numeroDeSeccionTarjetaUsuario + '_' + objects[i].id_ac}
                                style={{
                                    width: `${propiedadesDivLinkAux.width}px`, height: `${propiedadesDivLinkAux.height}px`,
                                    backgroundColor: 'transparent', position: 'absolute', zIndex: '1000', cursor: 'pointer',
                                    top: `${propiedadesDivLinkAux.top}px`, left: `${propiedadesDivLinkAux.left}px`
                                }}
                                onClick={() => { abirURLEnPantallaNueva(link_datos_ac_aux); }}
                            >
                            </div>
                        );

                        break;
                    case 'copyLottieAC':
                        let propiedadesDivCopyAux = TemplatepropiedadesDiv;
                        propiedadesDivCopyAux.id = 'divAccionAC' + seccionTarjetaUsuario.numeroDeSeccionTarjetaUsuario + '_' + objects[i].id_ac;

                        propiedadesDivCopyAux.top = (canvas.height * multiplicadorSeccion) + objects[i].top;
                        propiedadesDivCopyAux.left = offsetIzquierdoCanva + objects[i].left;

                        propiedadesDivCopyAux.width = objects[i].width * objects[i].scaleX;
                        propiedadesDivCopyAux.height = objects[i].height * objects[i].scaleY;

                        propiedadesDivCopyAux.dato_ac = objects[i].dato_ac;

                        const copy_datos_ac_aux = objects[i].dato_ac; // Se usa una variable local ya que si existen varios div solo toma el último valor

                        divs.push(
                            <div id={'divAccionAC' + seccionTarjetaUsuario.numeroDeSeccionTarjetaUsuario + '_' + objects[i].id_ac}
                                style={{
                                    width: `${propiedadesDivCopyAux.width}px`, height: `${propiedadesDivCopyAux.height}px`,
                                    backgroundColor: 'transparent', position: 'absolute', zIndex: '1000', cursor: 'pointer',
                                    top: `${propiedadesDivCopyAux.top}px`, left: `${propiedadesDivCopyAux.left}px`
                                }}
                                onClick={() => { copiarDatoPapelera(copy_datos_ac_aux); }}
                            >
                            </div>
                        );
                        break;
                    case 'confirmarAsistenciaLottieAC':
                        let propiedadesDivCAAux = TemplatepropiedadesDiv;
                        propiedadesDivCAAux.id = 'divAccionAC' + seccionTarjetaUsuario.numeroDeSeccionTarjetaUsuario + '_' + objects[i].id_ac;

                        propiedadesDivCAAux.top = (canvas.height * multiplicadorSeccion) + objects[i].top;
                        propiedadesDivCAAux.left = offsetIzquierdoCanva + objects[i].left;

                        propiedadesDivCAAux.width = objects[i].width * objects[i].scaleX;
                        propiedadesDivCAAux.height = objects[i].height * objects[i].scaleY;

                        propiedadesDivCAAux.dato_ac = objects[i].dato_ac;

                        const dato_acAux = objects[i].dato_ac;

                        divs.push(
                            <div id={'divAccionAC' + seccionTarjetaUsuario.numeroDeSeccionTarjetaUsuario + '_' + objects[i].id_ac}
                                style={{
                                    width: `${propiedadesDivCAAux.width}px`, height: `${propiedadesDivCAAux.height}px`,
                                    backgroundColor: 'transparent', position: 'absolute', zIndex: '1000', cursor: 'pointer',
                                    top: `${propiedadesDivCAAux.top}px`, left: `${propiedadesDivCAAux.left}px`
                                }}
                                onClick={() => { setDatoObjetoLottie(dato_acAux); setAbrirModalConfirmacion(true); }}
                            >
                            </div>
                        );
                        break;
                    case 'contadorLottieAC':
                        let propiedadesDivContadorAux = TemplatepropiedadesDiv;
                        propiedadesDivContadorAux.id = 'divAccionAC' + seccionTarjetaUsuario.numeroDeSeccionTarjetaUsuario + '_' + objects[i].id_ac;

                        propiedadesDivContadorAux.top = (canvas.height * multiplicadorSeccion) + objects[i].top;
                        propiedadesDivContadorAux.left = offsetIzquierdoCanva + objects[i].left;

                        propiedadesDivContadorAux.width = objects[i].width * objects[i].scaleX;
                        propiedadesDivContadorAux.height = objects[i].height * objects[i].scaleY;

                        const fecha = objects[i].ano_ac + "-" + objects[i].mes_ac + "-" + objects[i].dia_ac + "T12:00:00.000Z";

                        divs.push(
                            <div id={'divAccionAC' + seccionTarjetaUsuario.numeroDeSeccionTarjetaUsuario + '_' + objects[i].id_ac}
                                style={{
                                    width: '70%', height: `${propiedadesDivContadorAux.height}px`,
                                    backgroundColor: 'transparent', position: 'absolute', zIndex: '1000', cursor: 'pointer',
                                    top: `${propiedadesDivContadorAux.top}px`, left: `50%`, transform: 'translate(-50%, 0%)',
                                }}
                            >
                                <Timer deadline={fecha} coloTexto={objects[i].color_ac} />
                                {/* <Timer deadline={"2024-11-04T00:00:00.000Z"} /> */}
                                {/* <Timer deadline={getFechaContador()} /> */}
                            </div>
                        );
                        break;
                }             
            }
            return <>{divs}</>
        }
    }

    const buscarControladores = () => {

        if (canvas !== null) {
            const objects = canvas.getObjects();

            const centroPantallaPx = window.innerWidth / 2;
            const centroCanvaPx = anchoCanvas / 2;
            const offsetIzquierdoCanva = centroPantallaPx - centroCanvaPx;

            const multiplicadorSeccion = seccionTarjetaUsuario.numeroDeSeccionTarjetaUsuario - 1;

            const divs = [];

            for (var i in objects) {
                switch (objects[i].tipo_accion_ac) {
                    case 'Link':
                        let propiedadesDivLinkAux = TemplatepropiedadesDiv;
                        propiedadesDivLinkAux.id = 'divAccionAC' + seccionTarjetaUsuario.numeroDeSeccionTarjetaUsuario + '_' + objects[i].id_ac;

                        propiedadesDivLinkAux.top = (canvas.height * multiplicadorSeccion) + objects[i].top;
                        propiedadesDivLinkAux.left = offsetIzquierdoCanva + objects[i].left;

                        propiedadesDivLinkAux.width = objects[i].width * objects[i].scaleX;
                        propiedadesDivLinkAux.height = objects[i].height * objects[i].scaleY;

                        propiedadesDivLinkAux.dato_ac = objects[i].dato_accion_ac;

                        const link_datos_ac_aux = objects[i].dato_accion_ac; 

                        divs.push(
                            <div id={'divAccionAC' + seccionTarjetaUsuario.numeroDeSeccionTarjetaUsuario + '_' + objects[i].id_ac}
                                style={{
                                    backgroundColor: 'transparent', position: 'absolute', zIndex: '1000', cursor: 'pointer',
                                    top: `${propiedadesDivLinkAux.top}px`, left: `${propiedadesDivLinkAux.left}px`,
                                    width: `${propiedadesDivLinkAux.width}px`, height: `${propiedadesDivLinkAux.height}px`
                                }}
                                onClick={() => { abirURLEnPantallaNueva(link_datos_ac_aux); }}
                            >
                            </div>
                        );

                    break;
                    case 'Copiar':
                        let propiedadesDivCopyAux = TemplatepropiedadesDiv;
                        propiedadesDivCopyAux.id = 'divAccionAC' + seccionTarjetaUsuario.numeroDeSeccionTarjetaUsuario + '_' + objects[i].id_ac;

                        propiedadesDivCopyAux.top = (canvas.height * multiplicadorSeccion) + objects[i].top;
                        propiedadesDivCopyAux.left = offsetIzquierdoCanva + objects[i].left;

                        propiedadesDivCopyAux.width = objects[i].width * objects[i].scaleX;
                        propiedadesDivCopyAux.height = objects[i].height * objects[i].scaleY;

                        propiedadesDivCopyAux.dato_ac = objects[i].dato_accion_ac;

                        const copy_datos_ac_aux = objects[i].dato_accion_ac; // Se usa una variable local ya que si existen varios div solo toma el último valor

                        divs.push(
                            <div id={'divAccionAC' + seccionTarjetaUsuario.numeroDeSeccionTarjetaUsuario + '_' + objects[i].id_ac}
                                style={{
                                    width: `${propiedadesDivCopyAux.width}px`, height: `${propiedadesDivCopyAux.height}px`,
                                    backgroundColor: 'transparent', position: 'absolute', zIndex: '1000', cursor: 'pointer',
                                    top: `${propiedadesDivCopyAux.top}px`, left: `${propiedadesDivCopyAux.left}px`
                                }}
                                onClick={() => { copiarDatoPapelera(copy_datos_ac_aux); }}
                            >
                            </div>
                        );
                    break;       
                    case 'C.Asistencia':
                        let propiedadesDivCAAux = TemplatepropiedadesDiv;
                        propiedadesDivCAAux.id = 'divAccionAC' + seccionTarjetaUsuario.numeroDeSeccionTarjetaUsuario + '_' + objects[i].id_ac;

                        propiedadesDivCAAux.top = (canvas.height * multiplicadorSeccion) + objects[i].top;
                        propiedadesDivCAAux.left = offsetIzquierdoCanva + objects[i].left;

                        propiedadesDivCAAux.width = objects[i].width * objects[i].scaleX;
                        propiedadesDivCAAux.height = objects[i].height * objects[i].scaleY;

                        propiedadesDivCAAux.dato_ac = objects[i].dato_accion_ac;

                        const dato_acAux = objects[i].dato_accion_ac;

                        divs.push(
                            <div id={'divAccionAC' + seccionTarjetaUsuario.numeroDeSeccionTarjetaUsuario + '_' + objects[i].id_ac}
                                style={{
                                    width: `${propiedadesDivCAAux.width}px`, height: `${propiedadesDivCAAux.height}px`,
                                    backgroundColor: 'transparent', position: 'absolute', zIndex: '1000', cursor: 'pointer',
                                    top: `${propiedadesDivCAAux.top}px`, left: `${propiedadesDivCAAux.left}px`
                                }}
                                onClick={() => { setDatoObjetoLottie(dato_acAux); setAbrirModalConfirmacion(true); }}
                            >
                            </div>
                        );
                    break;      
                    case 'Contador':
                        let propiedadesDivContadorAux = TemplatepropiedadesDiv;
                        propiedadesDivContadorAux.id = 'divAccionAC' + seccionTarjetaUsuario.numeroDeSeccionTarjetaUsuario + '_' + objects[i].id_ac;

                        propiedadesDivContadorAux.top = (canvas.height * multiplicadorSeccion) + objects[i].top;
                        propiedadesDivContadorAux.left = offsetIzquierdoCanva + objects[i].left;

                        propiedadesDivContadorAux.width = objects[i].width * objects[i].scaleX;
                        propiedadesDivContadorAux.height = objects[i].height * objects[i].scaleY;

                        const fecha = objects[i].dato_accion_ac + "T12:00:00.000Z";

                        let colorContador = '4d4d4d';

                        if(objects[i].type === 'group'){
                            var items = objects[i]._objects;
                            items.forEach((object) => {
                                colorContador= object.get('fill');
                            });
                        }else{
                            colorContador = objects[i].get('fill');
                        }

                        divs.push(
                            <div id={'divAccionAC' + seccionTarjetaUsuario.numeroDeSeccionTarjetaUsuario + '_' + objects[i].id_ac}
                                style={{
                                    width: '70%', height: `${propiedadesDivContadorAux.height}px`,
                                    backgroundColor: 'transparent', position: 'absolute', zIndex: '1000', cursor: 'pointer',
                                    top: `${propiedadesDivContadorAux.top}px`, left: `50%`, transform: 'translate(-50%, 0%)',
                                }}
                            >
                                <Timer deadline={fecha} coloTexto={colorContador} />
                            </div>
                        );
                    break;                                             
                }
                
            }
            return <>{divs}</>
        }
    }
    /*                  END - CONTROLADORES                   */
    /**********************************************************/

    /**********************************************************/
    /*             BEGIN - CAMBIO ESCALA CANVA                */

    // Realiza el ajuste de los objetos de Canvas cuando el usuario
    // ingresa desde un Json desde la base de datos pero la edición esta cerrada.
    const resetScalaCanvaEdicionFinalizada = (anchoPantallaBD, altoPantallaDB, anchoImagenDB, altoImagenDB, fondoSeccionTarjeta) => {

        const anchoCanvasAux = window.innerWidth;
        const porcentajeDeReduccionAux = (anchoCanvasAux * 100) / seccionTarjetaUsuario.anchoImagen;
        const altoCanvasAux = (porcentajeDeReduccionAux * seccionTarjetaUsuario.altoImagen) / 100;

        setAltoCanvas(altoCanvasAux);
        setPorcentajeDeReduccion(porcentajeDeReduccionAux);
        setAnchoCanvas(anchoCanvasAux);

        canvas.setHeight(altoCanvasAux);
        canvas.setWidth(anchoCanvasAux);

        let porcentajeAjusteY = (altoCanvasAux * 100) / altoPantallaDB;
        let factorDeAjusteY = porcentajeAjusteY / 100;

        let porcentajeAjusteX = (anchoCanvasAux * 100) / anchoPantallaBD;
        let factorDeAjusteX = porcentajeAjusteX / 100;

        // Regreso al tamaño normal los objetos del canvas
        var objects = canvas.getObjects();
        for (var i in objects) {
            var scaleX = objects[i].scaleX;
            var scaleY = objects[i].scaleY;
            var left = objects[i].left;
            var top = objects[i].top;

            var tempScaleX = scaleX * factorDeAjusteY;
            var tempScaleY = scaleY * factorDeAjusteY;
            var tempLeft = left * factorDeAjusteY;
            var tempTop = top * factorDeAjusteY;

            objects[i].scaleX = tempScaleX;
            objects[i].scaleY = tempScaleY;
            objects[i].left = tempLeft;
            objects[i].top = tempTop;

            objects[i].setCoords();

            objects[i].hasRotatingPoint = true;
            objects[i].transparentCorners = false;
            objects[i].cornerColor = 'rgba(255, 87, 34, 0.7)';

            if (objects[i].type === 'contadorLottieAC') {
                objects[i].opacity = 0;
            }

            if (objects[i].tipo_accion_ac === 'Contador') {
                objects[i].opacity = 0;
            }

            // Quito las animaciones del fonto de tipo LOOP === TRUE ---- IMPORTANTE !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
            if (objects[i].type === 'lottieAC') {
                if(objects[i].getLoop()) {
                    setPathAnimacionFondo(objects[i].getSrc());
                    setAnimacionFondo(true);
                    objects[i].stop();
                    //console.log(objects[i].getSrc())
                    canvas.remove(objects[i]);
                }                
            }
        }

        // Indico que los elementos del canvas has sido ajustados - IMPORTANTE !!!!!!!!!!!!!!!!!!!!!
        setIsCanvaAjustado(true);
      
        /******
         * 
         * Se quita la imagne de background para quitarla de los renders y de esa forma bajar la cantidad de recursos utilizados.
         * 
        // When the image loads, set it as background image
        var img = new Image();

        // When the image loads, set it as background image
        img.onload = function () {
            var f_img = new fabric.Image(img);

            f_img.set({
                opacity: 1,
                scaleX: anchoCanvasAux / anchoImagenDB,
                scaleY: altoCanvasAux / altoImagenDB,

            }, { crossOrigin: 'Anonymous' });

            canvas.setBackgroundImage(f_img, canvas.requestRenderAll.bind(canvas));
        };

        // Define the Data URI
        var myDataURL = fondoSeccionTarjeta;
        img.src = myDataURL;
        *************************************************************************/
    }

    /*             END - CAMBIO ESCALA CANVA                */
    /**********************************************************/

    // Cargo el base64 de la imagen en una varible y genero un estilo para poner
    // la imagen de fonde del DIV que contiene el canva, de esta forma no se recarga
    // la imagen de fondo cada segundo, por los renders de lottie.

    const imageUrl = fondoSeccionTarjeta;

    const divStyle = {
        width: '100%', // Cambia el ancho según tus necesidades
        height: '100%', // Cambia la altura según tus necesidades
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: 'cover', // Ajustar la imagen para cubrir todo el div
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center', // Centrar la imagen en el div
    };

    const pantallaCanva = (
        <Fragment>
            <div id="contenedorMobile">
                <div id="contenedorCanvaMobile" style={divStyle}>
                    <canvas id="canvasMobile" ref={canvasRef} width="200" height="200" style={{ width: anchoCanvas + 'px', height: altoCanvas + 'px', zIndex: '1' }}></canvas>
                    { animacionFondo && 
                        <LottieAnimationSinCanva pathAnimacionFondo={pathAnimacionFondo} anchoCanvas={anchoCanvas} altoCanvas={altoCanvas}/>
                    }
                    {isCanvaAjustado === true ? buscarControladoresLottie() : ''}
                    {isCanvaAjustado === true ? buscarControladores() : ''}
                </div>
            </div>
        </Fragment>
    );

    return (
        <Fragment>
            {isLoading ? <Loading /> : pantallaCanva}
            {abrirModalConfirmacion ? <ConfirmarAsistencia codigoIngresado={codigoIngresado} // codiog hash
                datoObjetoLottie={datoObjetoLottie} // nombre o nombre del titulo
                isUsuarioRegistrado={isUsuarioRegistrado} // indica si el usuario ya se registro
                setIsUsuarioRegistrado={setIsUsuarioRegistrado} // actualiza si el usuario se pudo registrar
                abrirModalConfirmacion={abrirModalConfirmacion} // indica si se debe abrir el modal de formulario de carga
                setAbrirModalConfirmacion={setAbrirModalConfirmacion} /> // indica si se debe abrir o cerrar el formulario de carga
                : <></>}
            <ToastContainer/>
        </Fragment>
    );
}

export default Mobile;