import React, { useEffect, useState, Fragment } from "react";

import axios from "axios";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import HeaderFormulario from './HeaderFormulario'
import FormularioModal from "./FormularioModal";
import BotonOpciones from './BotonOpciones';
import UsuarioRegistrado from './UsuarioRegistrado';

// Componente internacionalizacion
import { useTranslation } from 'react-i18next';

import './ConfirmarAsistencia.css'

const ConfirmarAsistencia = ({ codigoIngresado, datoObjetoLottie,
    isUsuarioRegistrado, setIsUsuarioRegistrado,
    abrirModalConfirmacion, setAbrirModalConfirmacion }) => {

    // Cambio de idioma
    const [translate, i18n] = useTranslation('global');

    const initialFormState = {
        idRsvp: "",
        campo1: "",
        campo2: "",
        campo3: "",
        campo4: "",
        campo5: "",
        campo6: "",
        campo7: "",
        campo8: "",
        campo9: "",
        campo10: "",
        hashPublico: ""
    }

    const [invitado, setInvitado] = useState(initialFormState)
    const [data, setData] = useState("")
    const [errorPOST, setErrorPOST] = useState(false)
    const [modalInsertar, setModalInsertar] = useState(true)

    useEffect(() => {
        invitado.hashPublico = codigoIngresado;
        console.log("datoObjetoLottie " + datoObjetoLottie)
        modalOpen();
    }, []);

    const url = process.env.REACT_APP_INVITADO_BACKEND_URL + "rsvp.php";

    const modalCancel = () => {
        setAbrirModalConfirmacion(false);
        setInvitado(initialFormState);
        setModalInsertar(false);
    };

    const modalOpen = () => {
        setModalInsertar(true)
    }

    const peticionPost = async () => {

        invitado.campo1 = (typeof invitado.campo1 === 'string') && invitado.campo1.replace(/'/g, "''");
        invitado.campo2 = (typeof invitado.campo2 === 'string') && invitado.campo2.replace(/'/g, "''");
        invitado.campo3 = (typeof invitado.campo3 === 'string') && invitado.campo3.replace(/'/g, "''");
        invitado.campo4 = (typeof invitado.campo4 === 'string') && invitado.campo4.replace(/'/g, "''");
        invitado.campo5 = (typeof invitado.campo5 === 'string') && invitado.campo5.replace(/'/g, "''");
        invitado.campo6 = (typeof invitado.campo6 === 'string') && invitado.campo6.replace(/'/g, "''");
        invitado.campo7 = (typeof invitado.campo7 === 'string') && invitado.campo7.replace(/'/g, "''");
        invitado.campo8 = (typeof invitado.campo8 === 'string') && invitado.campo8.replace(/'/g, "''");
        invitado.campo9 = (typeof invitado.campo9 === 'string') && invitado.campo9.replace(/'/g, "''");
        invitado.campo10 = (typeof invitado.campo10 === 'string') && invitado.campo10.replace(/'/g, "''");

        await axios.post(url, invitado)
            .then(response => {
                const respuesta = response.data;
                console.log(respuesta);
                console.log(respuesta.status === 'error');
                if (respuesta.status === 'error') {
                    setData(respuesta.result.error_msg);
                    setIsUsuarioRegistrado(false);
                    setErrorPOST(true)
                } else {
                    setData(data.concat(response.data));
                    setIsUsuarioRegistrado(true);
                    setErrorPOST(false)
                }

                console.log(data)

            }).catch(error => {
                setErrorPOST(true)
                console.log("Error: " + error);
            })
        setModalInsertar(false)
    }

    // ---------------------- SECCION PANTALLA --------------------------

    const pantallaModal = (
        <>
            <Modal isOpen={modalInsertar} className="Modal">
                <ModalHeader style={{ display: "block" }} className="ModalHeader">
                    <HeaderFormulario titulo={translate('CA_HEADER_TITULO')} modalCancel={modalCancel} />
                </ModalHeader>
                <ModalBody className="ModalBody">
                    <FormularioModal datoObjetoLottie={datoObjetoLottie} invitado={invitado} setInvitado={setInvitado} codigoIngresado={codigoIngresado} />
                </ModalBody>
                <ModalFooter className="ModalFooter">
                    <BotonOpciones peticionPost={peticionPost} modalCancel={modalCancel} />
                </ModalFooter>
            </Modal>
        </>
    );

    const pantallaUsuarioRegistrado = (
        <>
            <Modal isOpen={true} className="Modal">
                <ModalHeader style={{ display: "block" }} className="ModalHeader">
                    <HeaderFormulario titulo={translate('CA_HEADER_TITULO_CONFIRMACION')} modalCancel={modalCancel} />
                </ModalHeader>
                <ModalBody className="ModalBody">
                    <div><h5>{translate('CA_BODY_REGISTRO_EXISTOSO')}</h5></div>
                </ModalBody>
                <ModalFooter className="ModalFooter">
                    <div className="botones">
                        &nbsp;&nbsp;&nbsp;
                        <button className="aceptarBotonModal" onClick={() => modalCancel()}>{translate('CA_BODY_REGISTRO_EXISTOSO_BOTON')}</button>
                        &nbsp;&nbsp;
                    </div>
                </ModalFooter>
            </Modal>
        </>
    );

    const pantallaRegistroError = (
        <>
            <Modal isOpen={true} className="Modal">
                <ModalHeader style={{ display: "block" }} className="ModalHeader">
                    <HeaderFormulario titulo={"Error."} modalCancel={modalCancel} />
                </ModalHeader>
                <ModalBody className="ModalBody">
                    <div><h6>{translate('CA_BODY_REGISTRO_NO_EXISTOSO_1')}</h6></div>
                    {data?.length === 0 ? <div><h6>{translate('CA_BODY_REGISTRO_NO_EXISTOSO_2')}</h6></div> : <div><h6>{data}</h6></div>}
                </ModalBody>
                <ModalFooter className="ModalFooter">
                    <div className="botones">
                        &nbsp;&nbsp;&nbsp;
                        <button className="aceptarBotonError" onClick={() => modalCancel()}>{translate('CA_FOOTER_BOTON_ERROR')}</button>
                        &nbsp;&nbsp;
                    </div>
                </ModalFooter>
            </Modal>
        </>
    );

    if (isUsuarioRegistrado) {
        return (
            <div className="AppModal">
                {pantallaUsuarioRegistrado}
            </div>
        );
    }


    if (errorPOST) {
        return (
            <div className="AppModal">
                {pantallaRegistroError}
            </div>
        )
    } else {
        return (
            <div className="AppModal">
                {pantallaModal}
            </div>
        );
    }

}

export default ConfirmarAsistencia;