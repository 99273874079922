import {create} from "zustand";
import { mountStoreDevtool } from 'simple-zustand-devtools';

export const useStoreUsuario = create((set, get) => ({
    usuarioStore: '',
    updateUsuarioStore: (newUsuario) => {
        const usuarioState = get().usuarioStore;
        //console.log("interno " +  get().usuarioStore)
        //console.log("nuevo " +  newUsuario)
        set({usuarioStore: newUsuario})
    },
    removeUsuario: () => set({}, true),
}));

if(process.env.REACT_APP_ENTORNO === "DESA"){
    mountStoreDevtool('useStoreUsuario', useStoreUsuario);
}

