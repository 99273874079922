import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
// Muestra el componente de loadging
import Loading from '../../../Componentes/Loading/Loading';
// Toast 
import { ToastContainer } from 'react-toastify';
import { showErrorToast, showInfoToast } from '../../../../servicios/toast/toast';
// Gestion de datos del backend
import { getCantidadDeLoginPersonalizado, getLoginPersonalizadoPaginado, getAllLoginPersonalizado } from "../../../../servicios/axios/restApi/useApiLoginPersonalizado";
import { getTarjetaUsuarioByCodigo  } from "../../../../servicios/axios/restApi/useApiTarjetaUsuario";
// Zustand
import { useStoreDatosPortada } from './../../../../servicios/stores/usuario/useStoreDatosPortada';

// copiar a memoria
import copy from "copy-to-clipboard";  

// Componente internacionalizacion
import { useTranslation } from 'react-i18next';

const ListaLoginPesonalizado = () => {

    //zustand
    const loginCampo1 = useStoreDatosPortada((state) => state.loginCampo1);
    const loginCampo2 = useStoreDatosPortada((state) => state.loginCampo2);
    const loginCampo3 = useStoreDatosPortada((state) => state.loginCampo3);

    const updateLoginCampo1 = useStoreDatosPortada((state) => state.updateLoginCampo1);
    const updateLoginCampo2 = useStoreDatosPortada((state) => state.updateLoginCampo2);
    const updateLoginCampo3 = useStoreDatosPortada((state) => state.updateLoginCampo3);


    let { search } = useLocation();
    let query = new URLSearchParams(search);

    // Cambio de idioma
    const [translate, i18n] = useTranslation('global');

    // Variables de componente
    const navigate = useNavigate();

    const [datosBD, setDatosBD] = useState([]);

    const [maxPaginas, setMaxPaginas] = useState(0);
    const [registroActual, setRegistroActual] = useState(0);

    const [paginaActual, setPaginaActual] = useState(0);

    const [paginaFinal, setPaginaFinal] = useState(true);
    const [paginaInicial, setPaginaInicial] = useState(true);

    const [isLoading, setIsLoading] = useState(true);

    const itemPorPagina = 25;

    const [filtro, setFiltro] = useState("");

    const [tarjetaCodigo, setTarjetaCodigo] = useState('');

    const [ idioma, setIdioma ] = useState('ES');

    useEffect(() => {
        try {
            setIsLoading(true);

            if(query.get("idioma")){
                setIdioma(query.get("idioma"));
                //console.log(query.get("idioma"))
                if( query.get("idioma") === 'ES' ){
                    i18n.changeLanguage('es');
                }else if( query.get("idioma") === 'IT' ){
                    i18n.changeLanguage('it');
                }else{
                    i18n.changeLanguage('en');
                }
                
            } else {
                setIdioma("ES");
                i18n.changeLanguage('es');
                //console.log("Idioma no definido, se asigna ES");
            }

            const tarjetaCodigo = query.get("codigo");
            setTarjetaCodigo(tarjetaCodigo);

            cargaDatos();

            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            showErrorToast(translate('LI_ERROR_001'));
        }
    }, []);

    useEffect(() => {
        setIsLoading(true);

        const cargaDatosPortada = async () => {
            const datosDBAux = await getTarjetaUsuarioByCodigo(query.get("codigo"));

            updateLoginCampo1(datosDBAux[0].loginCampo1);
            updateLoginCampo2(datosDBAux[0].loginCampo2);
            updateLoginCampo3(datosDBAux[0].loginCampo3);

            setIsLoading(false);
        }

        try {
            if (query.get("codigo").length > 0) {
                if(loginCampo1.length === 0){
                    cargaDatosPortada();                }
                
            } else {
                setIsLoading(false);
                showErrorToast(translate('LI_ERROR_003'));
            }

        } catch (e) {
            setIsLoading(false);
            showErrorToast(translate('LI_ERROR_001'));
        }
    }, []);

    const cargaDatos = async (filtro) => {
        setIsLoading(true);

        const auxCantidad = await getCantidadDeLoginPersonalizado(query.get("codigo"), filtro);

        if (auxCantidad.total > 0) {
            //console.log("auxCantidad :" + auxCantidad);

            setMaxPaginas(auxCantidad.total);
            const auxDatos = await getLoginPersonalizadoPaginado(1, itemPorPagina, query.get("codigo"), filtro);

            setDatosBD(auxDatos);

            setPaginaActual(1)
            const registroAcutalAux = itemPorPagina;
            setRegistroActual(registroAcutalAux);

            if (registroAcutalAux < auxCantidad.total) {
                setPaginaFinal(false);
            } else {
                setPaginaFinal(true);
            }
            setPaginaInicial(true);
        } else {
            setPaginaActual(1)
            setRegistroActual(itemPorPagina);
            setPaginaFinal(true);
            setPaginaInicial(true);
            setIsLoading(false);
        }
        setIsLoading(false);
    }

    const buscarInvitados = async () => {
        try {
            const filtroAux = filtro.replace(/'/g, "''");
            await cargaDatos(filtroAux);
        } catch (e) {
            setIsLoading(false);
            showErrorToast(translate('LI_ERROR_001'));
        }
    };

    const limpiarFiltro = async () => {
        setFiltro("");
        await cargaDatos("");
    }

    const paginaAnterior = async () => {
        setIsLoading(true);
        try {
            const paginaActualAux = paginaActual - 1;
            setPaginaActual(paginaActualAux);

            const auxDatos = await getLoginPersonalizadoPaginado(paginaActualAux, itemPorPagina, tarjetaCodigo, filtro);
            setDatosBD(auxDatos);

            const registroAcutalAux = registroActual - itemPorPagina;
            setRegistroActual(registroAcutalAux);

            if (maxPaginas <= registroAcutalAux) {
                setPaginaFinal(true);
            } else {
                setPaginaFinal(false);
            }

            if (paginaActualAux > 1) {
                setPaginaInicial(false);
            } else {
                setPaginaInicial(true);
            }
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            showErrorToast(translate('LI_ERROR_001'));
        }
    }

    const proximaPagina = async () => {
        setIsLoading(true);
        try {
            const paginaActualAux = paginaActual + 1;
            setPaginaActual(paginaActualAux);

            const auxDatos = await getLoginPersonalizadoPaginado(paginaActualAux, itemPorPagina, tarjetaCodigo, filtro);
            setDatosBD(auxDatos);

            const registroAcutalAux = registroActual + itemPorPagina;
            setRegistroActual(registroAcutalAux);

            if (registroAcutalAux < maxPaginas) {
                setPaginaFinal(false);
            } else {
                setPaginaFinal(true);
            }

            if (paginaActualAux > 1) {
                setPaginaInicial(false);
            } else {
                setPaginaInicial(true);
            }
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            showErrorToast(translate('LI_ERROR_001'));
        }
    }

    // Funciones del componente
    const nuevoLoginPersonalizado = () => {
        navigate("/agregarLoginPesonalizado/" + tarjetaCodigo + '/' + idioma);
    }

    const copiarAlPortapapeles = (urlCodigo) => {
        const urlPersonalizada = process.env.REACT_APP_BASE + "php/indexLP.php?urlCodigo=" + urlCodigo;
        copy(urlPersonalizada);
        alert("Url: " + urlPersonalizada);
    }

    const crearHeaderTablaInvitados = () => {
        const htmlElementos = [];

        htmlElementos.push(
            <th scope="col">#</th>
        );
        htmlElementos.push(
            <th scope="col">{translate('LP_CAMPO_1')}</th>
        );
        htmlElementos.push(
            <th scope="col">{translate('LP_CAMPO_2')}</th>
        );
        htmlElementos.push(
            <th scope="col">{translate('LP_CAMPO_3')}</th>
        );
        htmlElementos.push(
            <th scope="col">{translate('LP_CAMPO_CODIGO')}</th>
        );
        htmlElementos.push(
            <th scope="col">{translate('LP_CAMPO_IDIOMA')}</th>
        );
        htmlElementos.push(
            <th scope="col">{translate('LP_CAMPO_URL_CODE')}</th>
        );
        htmlElementos.push(
            <th scope="col">...</th>
        );

        return <>{htmlElementos}</>;
    };

    // Muestra el componente de carga cuando se hace una operación en contra la BD
    return (
        <>
            {isLoading ? <Loading /> : ""}

            <div className="container-fluid bg-white text-white pt-2 pb-2 text-center">
                <div className="row">
                    <div className="col-1">
                    </div>
                    <div className="col-5">
                        <h1 style={{ color: "black", fontSize: "1.1rem" }}><strong>{translate('LP_TABLE_TITULO')}</strong></h1>
                    </div>
                    <div className="col-5">
                        <input
                            type="text"
                            value={filtro}
                            onChange={(e) => setFiltro(e.target.value)}
                            placeholder={translate('LI_TABLE_BUSCADOR_FRASE')}
                            style={{borderRadius: '25px', border: '1px solid #222', padding: '0.7rem'}}
                        />
                        &nbsp;&nbsp;
                        <button onClick={buscarInvitados} className="btn btn-primary btn-sm">{translate('LI_TABLE_BUSCAR')}</button>
                        &nbsp;&nbsp;
                        <button onClick={limpiarFiltro} className="btn btn-secondary btn-sm">{translate('LI_TABLE_CLEAR')}</button>
                        &nbsp;&nbsp;
                    </div>
                    <div className="col-1">
                    </div>
                </div>
                <div className="row">
                    <div className="col-1">
                    </div>
                    <div className="col-10">
                        <table className="table">
                            <thead>
                                <tr>
                                    {crearHeaderTablaInvitados()}
                                    <th scope="col">
                                        {paginaInicial ? "" : <button type="button" className="btn btn-secondary btn-sm" onClick={paginaAnterior}>{translate('LI_TABLE_BOTON_PAGINA_ANTERIOR')}</button>}
                                        &nbsp; {translate('LI_TABLE_PAGE')}: {paginaActual} / {Math.ceil(maxPaginas / itemPorPagina)} &nbsp;
                                        {paginaFinal ? "" : <button type="button" className="btn btn-secondary btn-sm" onClick={proximaPagina}>{translate('LI_TABLE_BOTON_PAGINA_SIGUIENTE')}</button>}
                                        <button type="button" className="btn btn-primary btn-sm" onClick={nuevoLoginPersonalizado}>{translate('LP_BOTON_NUEVO')}</button>
                                    </th>

                                </tr>
                            </thead>
                            <tbody>
                                {
                                    datosBD.map((datoDB, index) => (
                                        <tr key={datoDB.idLoginPersonalizado}>
                                            <td>
                                                {<label> {index+1} </label>}
                                            </td>
                                            <td>
                                                {<label> {datoDB.campo1} </label>}
                                            </td>
                                            <td>
                                                {<label> {datoDB.campo2} </label>}
                                            </td>
                                            <td>
                                                {<label> {datoDB.campo3} </label>}
                                            </td>
                                            <td>
                                                {<label> {datoDB.tarjetaCodigo} </label>}
                                            </td>
                                            <td>
                                                {<label> {datoDB.idioma} </label>}
                                            </td>
                                            <td>
                                                {<label> {datoDB.urlCodigo} </label>}
                                            </td>                                                                                                                                                                                                                       
                                            <td>
                                                <Link to={`/editarLoginPesonalizado/${datoDB.idLoginPersonalizado}/${tarjetaCodigo}/${idioma}`}>
                                                    {translate('LI_BOTON_EDITAR')}
                                                </Link>
                                            </td>
                                            <td>
                                                <button type="button" className="btn btn-secondary btn-sm" onClick={()  => copiarAlPortapapeles(datoDB.urlCodigo)}>{translate('LP_BOTON_COPY_LINK')}</button>
                                            </td> 
                                        </tr>
                                    )
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="col-1">
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    )
}

export default ListaLoginPesonalizado