import React, {Fragment, useState, useRef} from 'react'

import ReCAPTCHA from "react-google-recaptcha";

// Componente internacionalizacion
import { useTranslation } from 'react-i18next';

import "./BotonOpciones.css"

const BotonOpciones = ( props ) => {

    // Cambio de idioma
    const [translate, i18n] = useTranslation('global');

    const captcha = useRef(null);

    const [soyHumano, setSoyHumano] = useState(false);

    const onChange = () => {
        if( captcha.current.getValue ){
            setSoyHumano(true);
        }
    }    

    return ( 
        <Fragment>
            {/* <div className="recapcha">
                <ReCAPTCHA
                    ref={captcha}
                    sitekey="6LczHvIjAAAAANjNPEb_aVvg9HITkVNUWnq1wLYN"
                    onChange={onChange}
                />    
            </div> */}
            <div className="botones">
                &nbsp;&nbsp;&nbsp;
                {/* <button className={soyHumano ? "registar" : "noRegistrar"} onClick={() => props.peticionPost()} disabled={soyHumano ? false : true} >{translate('CA_FOOTER_BOTON')}</button> */}
                <button className="registar" onClick={() => props.peticionPost()} >{translate('CA_FOOTER_BOTON')}</button>
                {/* <button  onClick={() => props.peticionPost()}  >Aceptar2</button> */}
                &nbsp;&nbsp;
            </div>
        </Fragment>
     );
}
 
export default BotonOpciones;