import React, {useEffect, useState, Fragment} from 'react'
import { useLocation  } from "react-router-dom";

import copy from "copy-to-clipboard";  

// Componente internacionalizacion
import { useTranslation } from 'react-i18next';

import "./LoginPersonalizadoCreador.css"

const  LoginPersonalizadoCreador = (props) => {

    let {search} = useLocation();
    let query = new URLSearchParams(search);

    // Cambio de idioma
    const [translate, i18n] = useTranslation('global');

    const initialFormState = {  
        campo1: "",
        campo2: "",
        campo3: "",
        campo4: "",
        campo5: "",
        campo6: "",
    }

    const [ invitado, setInvitado ]   = useState(initialFormState)
    const [ codigo, setCodigo ] = useState(0);
    const [ idioma, setIdioma ] = useState('ES');

    useEffect( () => {
        setCodigo(query.get("codigo"));

        if(query.get("idioma")){
            setIdioma(query.get("idioma"));
            //console.log(query.get("idioma"))
            if( query.get("idioma") === 'ES' ){
                i18n.changeLanguage('es');
            }else if( query.get("idioma") === 'IT' ){
                i18n.changeLanguage('it');
            }else{
                i18n.changeLanguage('en');
            }
            
        } else {
            setIdioma("ES");
            i18n.changeLanguage('es');
            //console.log("Idioma no definido, se asigna ES");
        }

        let invitadoAxu = {...invitado};

        if(query.get("t4")){
            invitadoAxu.campo4 = query.get("t4");
        }
        if(query.get("t5")){
            invitadoAxu.campo5 = query.get("t5");
        }
        if(query.get("t6")){
            invitadoAxu.campo6 = query.get("t6");
        }

        setInvitado(invitadoAxu);

    },[]); // useState - END

    const handleInputChange = event => {
        console.log(event)
        const { name, value } = event.target
        setInvitado({ ...invitado, [name]: value })
        console.log(invitado);
    }
    
    const [url, setUrl] = useState("");

    let urlAux = '';
    if(idioma === 'ES'){
        urlAux = process.env.REACT_APP_INVITADO_URL + "t?codigo=" + codigo + "&t1=" + invitado.campo1 + "&t2=" + invitado.campo2 + "&t3=" + invitado.campo3 + "&idioma=ES";
    }
    
    if(idioma === 'EN'){
        urlAux = process.env.REACT_APP_INVITADO_URL + "t?codigo=" + codigo + "&t1=" + invitado.campo1 + "&t2=" + invitado.campo2 + "&t3=" + invitado.campo3 + "&idioma=EN";
    }

    if(idioma === 'IT'){
        urlAux = process.env.REACT_APP_INVITADO_URL + "t?codigo=" + codigo + "&t1=" + invitado.campo1 + "&t2=" + invitado.campo2 + "&t3=" + invitado.campo3 + "&idioma=IT";
    }

    // URL
    const urlCopiar = () => {
        let encodedURL = encodeURI(urlAux);
        console.log(encodedURL)
        copy(encodedURL);
        alert(encodedURL);
    }

    // URL open
    const urlOpen = () => {
        window.open(urlAux);
    }

    return ( 
        <Fragment>
            <div>
                <br />
                <br />
                <br />
                 <div className="row pt-1">
                    <div className="col-1"></div>
                    <div className="col-10 ">

                        <div className="row pt-1">
                            <div className="col-6" style={{backgroundColor:'transparent'}}>
                                <div id="datosLogin" style={{backgroundColor:'transparent', padding:'5rem 5rem 5rem 5rem' }}>

                                    <label htmlFor="campo1" style={{fontSize:'2.5rem', fontFamily:'vijaya'}}>{translate('LP_TITLE')}</label>
                                    
                                    <br />
                                    <br />

                                    <label htmlFor="campo1" style={{fontSize:'1.5rem', fontFamily:'vijaya'}}>{translate('LP_RENGLON_1')}</label>
                                    <span>: &nbsp; </span>
                                    <input className="form-control"  type="text"
                                        name="campo1" id="campo1"
                                        onChange={handleInputChange} value={invitado.campo1} 
                                    />
                                    <br />
                                    <label htmlFor="campo2" style={{fontSize:'1.5rem', fontFamily:'vijaya'}}>{translate('LP_RENGLON_2')}</label>
                                    <span>: &nbsp; </span>
                                    <input className="form-control"  type="text"
                                        name="campo2" id="campo2"
                                        onChange={handleInputChange} value={invitado.campo2} 
                                    />
                                    <br />
                                    <label htmlFor="campo3" style={{fontSize:'1.5rem', fontFamily:'vijaya'}}>{translate('LP_RENGLON_3')}</label>
                                    <span>: &nbsp; </span>
                                    <input className="form-control"  type="text"
                                        name="campo3" id="campo3"
                                        onChange={handleInputChange} value={invitado.campo3} 
                                    />
                                    <br />
                                    <br />
                                    <div id="urlLogin" onClick={urlCopiar}>
                                        {/* <a style={{fontSize:'1.2rem'}} href={urlAux} target="_new" className="">{urlAux}</a> */}
                                        {/* <label htmlFor="" style={{fontSize:'1.2rem'}}> {urlAux} </label> */}
                                        <button className="btn btn-secondary" onClick={urlCopiar}>{translate('LP_BOTON_COPIAR')}</button>
                                        <br />
                                        <br />
                                        <label htmlFor="campo3" style={{fontSize:'1.5rem', fontFamily:'vijaya'}}>{translate('LP_TEXTO_WSP')}</label>
                                    </div> 
                                </div>
                            </div>
                            <div className="col-6 " style={{backgroundColor:'transparent'}}>
                                <div className="card" id="LoginPersonalizado" style={{ border: '1px dashed lightslategray', width: '23rem', height: '40rem', backgroundColor: 'whitesmoke', position:'relative'}}>
                                    <div className="card-body">
                                        <label htmlFor="" style={{paddingTop:'0.1rem'}}>&nbsp;</label>
                                        <h1 className='t1' content="notranslate"> {invitado.campo1} </h1>
                                        <h1 className='t2' content="notranslate"> {invitado.campo2} </h1>
                                        <h1 className='t3' content="notranslate"> {invitado.campo3} </h1>
                                        <center><h1 className='t4' content="notranslate"> {invitado.campo4} </h1></center>
                                        <img id="swipeUp" src="./assets/img/PantallaClickPersonalizado.svg" alt=""></img>
                                        <h1 className='t5' content="notranslate"> {invitado.campo5} </h1>
                                        <h1 className='t6' content="notranslate"> {invitado.campo6} </h1>
                                    </div>
                                </div>   
                            </div>
                        </div>
                    </div>
                    <div className="col-1 "></div>
                </div>

                

            </div>
            
        </Fragment>                  
    );



}
 
export default LoginPersonalizadoCreador;