import axios from "axios";

const apiURLBase = process.env.REACT_APP_INVITADO_BACKEND_URL;

const _imprimiConsulta = (url) => {
    if(process.env.REACT_APP_ENTORNO === "DESA"){
        console.log(url);
    }
}

// Busca si el fondo de tarjeta esta registrado
export const getLoginPersonalizadoPaginado = async (pagina, count='25', tarjetaCodigo='0', filtro='') => {
    const url = apiURLBase + "loginPersonalizado.php?page=" + pagina + "&count=" + count + "&tarjetaCodigo=" + tarjetaCodigo + '&filtro=' + filtro;
    _imprimiConsulta(url);
    const { data } = await axios.get(url);
    return data;
}

// Busca si el fondo de tarjeta esta registrado
export const getCantidadDeLoginPersonalizado =  async (tarjetaCodigo='', filtro='') => {
    const url = apiURLBase + "loginPersonalizado.php?total=total&tarjetaCodigo=" + tarjetaCodigo + '&filtro=' + filtro;;
    _imprimiConsulta(url);
    const { data } = await axios.get(url);
    return data;
}

// recupera todos los fondos de tarjetas
export const getAllLoginPersonalizado =  async (tarjetaCodigo='0') => {
    const url = apiURLBase + "loginPersonalizado.php?all=all&tarjetaCodigo=" + tarjetaCodigo;
    _imprimiConsulta(url);
    const { data } = await axios.get(url);
    return data;
}

// Busca si el fondo de tarjeta esta registrado
export const getLoginPersonalizadoById =  async (idLoginPersonalizado, tarjetaCodigo) => {
    const url = apiURLBase + "loginPersonalizado.php?idLoginPersonalizado=" + idLoginPersonalizado + "&tarjetaCodigo=" + tarjetaCodigo;
    _imprimiConsulta(url);
    const { data } = await axios.get(url);
    return data;
}

// Registra un nuevo fondo de tarjeta
export const postLoginPersonalizado = async (modeloLoginPersonalizado) => {
    const url = apiURLBase + "loginPersonalizado.php";  
    _imprimiConsulta(url);
    const { data } = await axios.post(url, modeloLoginPersonalizado);
    return data;
};

// Actualizar los datos de un fondo de tarjeta
export const putLoginPersonalizado = async (modeloLoginPersonalizado) => {
    const url = apiURLBase + "loginPersonalizado.php";  
    console.log(modeloLoginPersonalizado)
    _imprimiConsulta(url);
    const { data } = await axios.put(url, modeloLoginPersonalizado);
    return data;
};

// Delete los datos de un fondo de tarjeta
export const deleteLoginPersonalizado = async (idLoginPersonalizado, tarjetaCodigo) => {
    const url = apiURLBase + "loginPersonalizado.php?idLoginPersonalizado=" + idLoginPersonalizado + "&tarjetaCodigo=" + tarjetaCodigo;  
    _imprimiConsulta(url);
    const { data } = await axios.delete(url);
    return data;
};