import axios from "axios";

const apiURLBase = process.env.REACT_APP_BACKEND_URL;

const _imprimiConsulta = (url) => {
    if(process.env.REACT_APP_ENTORNO === "DESA"){
        console.log(url);
    }
}

// Busca si el fondo de tarjeta esta registrado
export const getFondosTarjetasPaginado = async (pagina) => {
    const url = apiURLBase + "fondoTarjeta.php?page=" + pagina;
    _imprimiConsulta(url);
    const { data } = await axios.get(url);
    return data;
}

// Busca si el fondo de tarjeta esta registrado
export const getCantidadDeFondosDisponibles = async () => {
    const url = apiURLBase + "fondoTarjeta.php?total=total";
    _imprimiConsulta(url);
    const { data } = await axios.get(url);
    return data;
}

// recupera todos los fondos de tarjetas
export const getAllFondosTarjetas = async () => {
    const url = apiURLBase + "fondoTarjeta.php?all";
    _imprimiConsulta(url);
    const { data } = await axios.get(url);
    return data;
}

// recupera todos los fondos de tarjetas de un modelo
export const getAllFondosTarjetasByModelo = async (idModeloTarjeta) => {
    const url = apiURLBase + "fondoTarjeta.php?idModeloTarjeta=" + idModeloTarjeta;
    _imprimiConsulta(url);
    const { data } = await axios.get(url);
    return data;
}

// Busca si el fondo de tarjeta esta registrado
export const getFondoTarjetaById = async (idFondoTarjeta) => {
    const url = apiURLBase + "fondoTarjeta.php?idFondoTarjeta=" + idFondoTarjeta;
    _imprimiConsulta(url);
    const { data } = await axios.get(url);
    return data;
}

// Registra un nuevo fondo de tarjeta
export const postRegistroFondoTarjeta = async (modeloFondoTarjeta) => {
    const url = apiURLBase + "fondoTarjeta.php";  
    _imprimiConsulta(url);
    const { data } = await axios.post(url, modeloFondoTarjeta);
    return data;
};

// Actualizar los datos de un fondo de tarjeta
export const putRegistroFondoTarjeta = async (modeloFondoTarjeta) => {
    const url = apiURLBase + "fondoTarjeta.php";  
    _imprimiConsulta(url);
    const { data } = await axios.put(url, modeloFondoTarjeta);
    return data;
};

export const deleteFondoTarjeta = async (idFondoTarjeta) => {
    const url = apiURLBase + "fondoTarjeta.php?idFondoTarjeta=" + idFondoTarjeta;  
    _imprimiConsulta(url);
    const { data } = await axios.delete(url);
    return data;
};









