import axios from "axios";

const apiURLBase = process.env.REACT_APP_BACKEND_URL;

const _imprimiConsulta = (url) => {
    if(process.env.REACT_APP_ENTORNO === "DESA"){
        console.log(url);
    }
}

export const getCodigoTarjetaPaginado = async (pagina) => {
    const url = apiURLBase + "tarjetaCodigo.php?page=" + pagina;
    _imprimiConsulta(url);
    const { data } = await axios.get(url);
    return data;
}

export const getCantidadDeCodigosDeTarjetas =  async () => {
    const url = apiURLBase + "tarjetaCodigo.php?total=total";
    _imprimiConsulta(url);
    const { data } = await axios.get(url);
    return data;
}

export const getRegistroTarjetaCodigoByCodigo = async (codigoTarjeta) => {
    const url = apiURLBase + "tarjetaCodigo.php?codigoTarjeta=" + codigoTarjeta;  
    _imprimiConsulta(url);
    const { data } = await axios.get(url);
    return data;

};

export const getRegistroTarjetaCodigoById = async (idTarjetaCodigo) => {
    const url = apiURLBase + "tarjetaCodigo.php?idTarjetaCodigo=" + idTarjetaCodigo;  
    _imprimiConsulta(url);
    const { data } = await axios.get(url);
    return data;

};

export const postRegistroTarjetaCodigo = async (modeloTarjetaCodigo) => {
    const url = apiURLBase + "tarjetaCodigo.php";  
    _imprimiConsulta(url);
    const { data } = await axios.post(url, modeloTarjetaCodigo);
    return data;
};

export const putRegistroTarjetaCodigo = async (modeloTarjetaCodigo) => {
    const url = apiURLBase + "tarjetaCodigo.php";  
    _imprimiConsulta(url);
    const { data } = await axios.put(url, modeloTarjetaCodigo);
    return data;
};

export const deleteRegistroTarjetaCodigoById = async (idTarjetaCodigo) => {
    const url = apiURLBase + "tarjetaCodigo.php?idTarjetaCodigo=" + idTarjetaCodigo;  
    _imprimiConsulta(url);
    const { data } = await axios.delete(url);
    return data;
};
