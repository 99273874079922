import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
// Muestra el componente de loadging
import Loading from '../../../Componentes/Loading/Loading';
// Toast 
import { ToastContainer } from 'react-toastify';
import { showErrorToast, showInfoToast } from '../../../../servicios/toast/toast';
// Gestion de datos del backend
import { getCantidadDeInvitados, getInvitadosPaginado, getAllInvitados, getAllRsvpCamposHashPublico } from "../../../../servicios/axios/restApi/useApiInvitados";

// Componente internacionalizacion
import { useTranslation } from 'react-i18next';

const ListaInvitados = () => {

    let { search } = useLocation();
    let query = new URLSearchParams(search);

    // Cambio de idioma
    const [translate, i18n] = useTranslation('global');

    // Variables de componente
    const navigate = useNavigate();

    const [datosBD, setDatosBD] = useState([]);

    const [maxPaginas, setMaxPaginas] = useState(0);
    const [registroActual, setRegistroActual] = useState(0);

    const [paginaActual, setPaginaActual] = useState(0);

    const [paginaFinal, setPaginaFinal] = useState(true);
    const [paginaInicial, setPaginaInicial] = useState(true);

    const [isLoading, setIsLoading] = useState(true);

    const itemPorPagina = 25;

    const [filtro, setFiltro] = useState("");

    const [hashPrivado, setHashPrivado] = useState('');
    const [hashPublico, setHashPublico] = useState('');

    const [camposRSVP, setCamposRSVP] = useState([]);

    const [ idioma, setIdioma ] = useState('ES');

    useEffect(() => {
        try {
            setIsLoading(true);

            if(query.get("idioma")){
                setIdioma(query.get("idioma"));
                //console.log(query.get("idioma"))
                if( query.get("idioma") === 'ES' ){
                    i18n.changeLanguage('es');
                }else if( query.get("idioma") === 'IT' ){
                    i18n.changeLanguage('it');
                }else{
                    i18n.changeLanguage('en');
                }
                
            } else {
                setIdioma("ES");
                i18n.changeLanguage('es');
                //console.log("Idioma no definido, se asigna ES");
            }


            const buscarCamposRSVP = async (hashPublico) => {
                const camposRsvpAux = await getAllRsvpCamposHashPublico(hashPublico);
                setCamposRSVP(camposRsvpAux);
            };
            const hashPrivado = query.get("hashPrivado");
            setHashPrivado(hashPrivado);

            const hashPublico = query.get("hashPublico");
            setHashPublico(hashPublico);

            buscarCamposRSVP(hashPublico);
            cargaDatos();

            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            showErrorToast(translate('LI_ERROR_001'));
        }
    }, []);

    const cargaDatos = async (filtro) => {
        setIsLoading(true);

        const auxCantidad = await getCantidadDeInvitados(query.get("hashPrivado"), filtro);

        if (auxCantidad.total > 0) {
            //console.log("auxCantidad :" + auxCantidad);

            setMaxPaginas(auxCantidad.total);
            const auxDatos = await getInvitadosPaginado(1, itemPorPagina, query.get("hashPrivado"), filtro);

            setDatosBD(auxDatos);

            setPaginaActual(1)
            const registroAcutalAux = itemPorPagina;
            setRegistroActual(registroAcutalAux);

            if (registroAcutalAux < auxCantidad.total) {
                setPaginaFinal(false);
            } else {
                setPaginaFinal(true);
            }
            setPaginaInicial(true);
        } else {
            setPaginaActual(1)
            setRegistroActual(itemPorPagina);
            setPaginaFinal(true);
            setPaginaInicial(true);
            setIsLoading(false);
        }
        setIsLoading(false);
    }

    const buscarInvitados = async () => {
        try {
            const filtroAux = filtro.replace(/'/g, "''");
            await cargaDatos(filtroAux);
        } catch (e) {
            setIsLoading(false);
            showErrorToast(translate('LI_ERROR_001'));
        }
    };

    const limpiarFiltro = async () => {
        setFiltro("");
        await cargaDatos("");
    }

    // Funciones del componente
    const nuevoModeloTarjeta = () => {
        navigate("/agregarModelosTarjeta");
    }

    const paginaAnterior = async () => {
        setIsLoading(true);
        try {
            const paginaActualAux = paginaActual - 1;
            setPaginaActual(paginaActualAux);

            const auxDatos = await getInvitadosPaginado(paginaActualAux, itemPorPagina, hashPrivado, filtro);
            setDatosBD(auxDatos);

            const registroAcutalAux = registroActual - itemPorPagina;
            setRegistroActual(registroAcutalAux);

            if (maxPaginas <= registroAcutalAux) {
                setPaginaFinal(true);
            } else {
                setPaginaFinal(false);
            }

            if (paginaActualAux > 1) {
                setPaginaInicial(false);
            } else {
                setPaginaInicial(true);
            }
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            showErrorToast(translate('LI_ERROR_001'));
        }
    }

    const proximaPagina = async () => {
        setIsLoading(true);
        try {
            const paginaActualAux = paginaActual + 1;
            setPaginaActual(paginaActualAux);

            const auxDatos = await getInvitadosPaginado(paginaActualAux, itemPorPagina, hashPrivado, filtro);
            setDatosBD(auxDatos);

            const registroAcutalAux = registroActual + itemPorPagina;
            setRegistroActual(registroAcutalAux);

            if (registroAcutalAux < maxPaginas) {
                setPaginaFinal(false);
            } else {
                setPaginaFinal(true);
            }

            if (paginaActualAux > 1) {
                setPaginaInicial(false);
            } else {
                setPaginaInicial(true);
            }
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            showErrorToast(translate('LI_ERROR_001'));
        }
    }

    const crearHeaderTablaInvitados = () => {
        const htmlElementos = [];

        camposRSVP.forEach((campoRsvp) => {
            if (campoRsvp.tituloCampo.length > 0) {
                htmlElementos.push(
                    <th scope="col">{campoRsvp.tituloCampo}</th>
                );
            }
        });

        return <>{htmlElementos}</>;
    };

    const exportExcel = async (event) => {
        try {
            setIsLoading(true);

            const auxRsvpInvitados = await getAllInvitados(query.get("hashPrivado"));

            if (auxRsvpInvitados.length > 0) {
                event.preventDefault()

                // Headers for each column
                //let cabeceraCSV = ['Id;Nombres;Cantidad;Alergias / Intolerancias / Vegano;Musica;Traslado;N/A;N/A;N/A;N/A;N/A']
                let cabeceraCSV = camposRSVP.reduce((resultado, cabeceraDatos) => {
                    const {
                        id,
                        hashPrivado,
                        nombreCampo,
                        tituloCampo,
                        tipoCampo,
                        hashPublico
                    } = cabeceraDatos;

                    resultado = [...resultado, tituloCampo];
                    return resultado;
                }, '');

                const cabeceraVectorCSV = [cabeceraCSV.join(';')]
                console.log(cabeceraVectorCSV);

                // Datos de la base de datos
                let rsvpDatosCSV = datosBD.reduce((resultado, invitado) => {
                    let {
                        idRsvp,
                        campo1,
                        campo2,
                        campo3,
                        campo4,
                        campo5,
                        campo6,
                        campo7,
                        campo8,
                        campo9,
                        campo10,
                    } = invitado;

                    if(i18n.language === 'es'){
                        resultado.push([campo1, campo2, campo3, campo4, campo5, campo6, campo7, campo8, campo9, campo10,].join(';'));
                    }

                    if(i18n.language === 'en'){

                        if(campo1.split(" ").join("") === 'Si'){campo1 = 'Yes'}
                        if(campo2.split(" ").join("") === 'Si'){campo2 = 'Yes'}
                        if(campo3.split(" ").join("") === 'Si'){campo3 = 'Yes'}
                        if(campo4.split(" ").join("") === 'Si'){campo4 = 'Yes'}
                        if(campo5.split(" ").join("") === 'Si'){campo5 = 'Yes'}
                        if(campo6.split(" ").join("") === 'Si'){campo6 = 'Yes'}
                        if(campo7.split(" ").join("") === 'Si'){campo7 = 'Yes'}
                        if(campo8.split(" ").join("") === 'Si'){campo8 = 'Yes'}
                        if(campo9.split(" ").join("") === 'Si'){campo9 = 'Yes'}
                        if(campo10.split(" ").join("") === 'Si'){campo10 = 'Yes'}

                        resultado.push([campo1, campo2, campo3, campo4, campo5, campo6, campo7, campo8, campo9, campo10,].join(';'));
                    }

                    if(i18n.language === 'it'){

                        if(campo1.split(" ").join("") === 'Si'){campo1 = 'SÌ'}
                        if(campo2.split(" ").join("") === 'Si'){campo2 = 'SÌ'}
                        if(campo3.split(" ").join("") === 'Si'){campo3 = 'SÌ'}
                        if(campo4.split(" ").join("") === 'Si'){campo4 = 'SÌ'}
                        if(campo5.split(" ").join("") === 'Si'){campo5 = 'SÌ'}
                        if(campo6.split(" ").join("") === 'Si'){campo6 = 'SÌ'}
                        if(campo7.split(" ").join("") === 'Si'){campo7 = 'SÌ'}
                        if(campo8.split(" ").join("") === 'Si'){campo8 = 'SÌ'}
                        if(campo9.split(" ").join("") === 'Si'){campo9 = 'SÌ'}
                        if(campo10.split(" ").join("") === 'Si'){campo10 = 'SÌ'}

                        resultado.push([campo1, campo2, campo3, campo4, campo5, campo6, campo7, campo8, campo9, campo10,].join(';'));
                    }

                    
                    return resultado
                }, [])

                console.log(rsvpDatosCSV);

                downloadFile({
                    data: [...cabeceraVectorCSV, ...rsvpDatosCSV].join('\n'),
                    fileName: 'ListaInvitados.csv',
                    fileType: 'text/csv;charset=utf-8',
                })
            } else {
                showInfoToast(translate('LI_INFO_001'));
            }

            setIsLoading(false);
        } catch (e) {
            console.log(e)
            setIsLoading(false);
            showErrorToast(translate('LI_ERROR_002'));
        }
    }

    const downloadFile = ({ data, fileName, fileType }) => {

        const blob = new Blob(["\uFEFF" + data], { type: fileType })

        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt);
        a.remove();
    }

    const tablaCA_ES = (
        <>
            <tbody>
            {
                datosBD.map(datoDB => (
                    <tr key={datoDB.idModeloTarjeta}>
                        <td>
                            { <label> {datoDB.campo1} </label>}
                        </td>
                        <td>
                            { <label> {datoDB.campo2} </label>}
                        </td>
                        <td>
                            { <label> {datoDB.campo3} </label>}                                            
                        </td>
                        <td>
                            { <label> {datoDB.campo4} </label>}
                        </td>
                        <td>
                            { <label> {datoDB.campo5} </label>}
                        </td>
                        <td>
                            { <label> {datoDB.campo6} </label>}
                        </td>
                        <td>
                            { <label> {datoDB.campo7} </label>}
                        </td>
                        <td>
                            { <label> {datoDB.campo8} </label>}
                        </td>
                        <td>
                            { <label> {datoDB.campo9} </label>}
                        </td>
                        <td>
                            { <label> {datoDB.campo10} </label>}
                        </td>                                                                                                                                                                                
                        <td>
                            <Link to={`/editarInvitado/${datoDB.idRsvp}/${hashPublico}/${hashPrivado}/${idioma}`}>
                                {translate('LI_BOTON_EDITAR')}
                            </Link>
                        </td>
                    </tr>
                ))
            }
            </tbody>
        </>
    );

    const tablaCA_EN = (
        <>
            <tbody>
            {
                datosBD.map(datoDB => (
                    <tr key={datoDB.idModeloTarjeta}>
                        <td>
                            {
                                (datoDB.campo1.split(" ").join("") === 'Si') ? <label> Yes </label> : <label> {datoDB.campo1} </label>
                            }
                        </td>
                        <td>
                            {
                                (datoDB.campo2.split(" ").join("") === 'Si') ? <label> Yes </label> : <label> {datoDB.campo2} </label>
                            }
                        </td>
                        <td>
                            {
                                (datoDB.campo3.split(" ").join("") === 'Si') ? <label> Yes </label> : <label> {datoDB.campo3} </label>
                            }                                                
                        </td>
                        <td>
                            {
                                (datoDB.campo4.split(" ").join("") === 'Si') ? <label> Yes </label> : <label> {datoDB.campo4} </label>
                            }
                        </td>
                        <td>
                            {
                                (datoDB.campo5.split(" ").join("") === 'Si') ? <label> Yes </label> : <label> {datoDB.campo5} </label>
                            }
                        </td>
                        <td>
                            {
                                (datoDB.campo6.split(" ").join("") === 'Si') ? <label> Yes </label> : <label> {datoDB.campo6} </label>
                            }
                        </td>
                        <td>
                            {
                                (datoDB.campo7.split(" ").join("") === 'Si') ? <label> Yes </label> : <label> {datoDB.campo7} </label>
                            }
                        </td>
                        <td>
                            {
                                (datoDB.campo8.split(" ").join("") === 'Si') ? <label> Yes </label> : <label> {datoDB.campo8} </label>
                            }
                        </td>
                        <td>
                            {
                                (datoDB.campo9.split(" ").join("") === 'Si') ? <label> Yes </label> : <label> {datoDB.campo9} </label>
                            }
                        </td>
                        <td>
                            {
                                (datoDB.campo10.split(" ").join("") === 'Si') ? <label> Yes </label> : <label> {datoDB.campo10} </label>
                            }
                        </td>                                                                                                                                                                                
                        <td>
                            <Link to={`/editarInvitado/${datoDB.idRsvp}/${hashPublico}/${hashPrivado}/${idioma}`}>
                                {translate('LI_BOTON_EDITAR')}
                            </Link>
                        </td>
                    </tr>
                ))
            }
            </tbody>
        </>
    );

    const tablaCA_IT = (
        <>
            <tbody>
            {
                datosBD.map(datoDB => (
                    <tr key={datoDB.idModeloTarjeta}>
                        <td>
                            {
                                (datoDB.campo1.split(" ").join("") === 'Si') ? <label> SÌ </label> : <label> {datoDB.campo1} </label>
                            }
                        </td>
                        <td>
                            {
                                (datoDB.campo2.split(" ").join("") === 'Si') ? <label> SÌ </label> : <label> {datoDB.campo2} </label>
                            }
                        </td>
                        <td>
                            {
                                (datoDB.campo3.split(" ").join("") === 'Si') ? <label> SÌ </label> : <label> {datoDB.campo3} </label>
                            }                                                
                        </td>
                        <td>
                            {
                                (datoDB.campo4.split(" ").join("") === 'Si') ? <label> SÌ </label> : <label> {datoDB.campo4} </label>
                            }
                        </td>
                        <td>
                            {
                                (datoDB.campo5.split(" ").join("") === 'Si') ? <label> SÌ </label> : <label> {datoDB.campo5} </label>
                            }
                        </td>
                        <td>
                            {
                                (datoDB.campo6.split(" ").join("") === 'Si') ? <label> SÌ </label> : <label> {datoDB.campo6} </label>
                            }
                        </td>
                        <td>
                            {
                                (datoDB.campo7.split(" ").join("") === 'Si') ? <label> SÌ </label> : <label> {datoDB.campo7} </label>
                            }
                        </td>
                        <td>
                            {
                                (datoDB.campo8.split(" ").join("") === 'Si') ? <label> SÌ </label> : <label> {datoDB.campo8} </label>
                            }
                        </td>
                        <td>
                            {
                                (datoDB.campo9.split(" ").join("") === 'Si') ? <label> SÌ </label> : <label> {datoDB.campo9} </label>
                            }
                        </td>
                        <td>
                            {
                                (datoDB.campo10.split(" ").join("") === 'Si') ? <label> SÌ </label> : <label> {datoDB.campo10} </label>
                            }
                        </td>                                                                                                                                                                                
                        <td>
                            <Link to={`/editarInvitado/${datoDB.idRsvp}/${hashPublico}/${hashPrivado}/${idioma}`}>
                                {translate('LI_BOTON_EDITAR')}
                            </Link>
                        </td>
                    </tr>
                ))
            }
            </tbody>
        </>
    );


    // Muestra el componente de carga cuando se hace una operación en contra la BD
    return (
        <>
            {isLoading ? <Loading /> : ""}

            <div className="container-fluid bg-white text-white pt-2 pb-2 text-center">
                <div className="row">
                    <div className="col-1">
                    </div>
                    <div className="col-5">
                        <h1 style={{ color: "black", fontSize: "1.1rem" }}><strong>{translate('LI_TABLE_TITULO')}</strong></h1>
                    </div>
                    <div className="col-5">
                        <input
                            type="text"
                            value={filtro}
                            onChange={(e) => setFiltro(e.target.value)}
                            placeholder={translate('LI_TABLE_BUSCADOR_FRASE')}
                            style={{borderRadius: '25px', border: '1px solid #222', padding: '0.7rem'}}
                        />
                        &nbsp;&nbsp;
                        <button onClick={buscarInvitados} className="btn btn-primary btn-sm">{translate('LI_TABLE_BUSCAR')}</button>
                        &nbsp;&nbsp;
                        <button onClick={limpiarFiltro} className="btn btn-secondary btn-sm">{translate('LI_TABLE_CLEAR')}</button>
                        &nbsp;&nbsp;
                        <button onClick={exportExcel} className="btn btn-secondary btn-sm">{translate('LI_TABLE_EXPORT')}</button>
                    </div>
                    <div className="col-1">
                    </div>
                </div>
                <div className="row">
                    <div className="col-1">
                    </div>
                    <div className="col-10">
                        <table className="table">
                            <thead>
                                <tr>
                                    {crearHeaderTablaInvitados()}
                                    <th scope="col">
                                        {paginaInicial ? "" : <button type="button" className="btn btn-secondary btn-sm" onClick={paginaAnterior}>{translate('LI_TABLE_BOTON_PAGINA_ANTERIOR')}</button>}
                                        &nbsp; {translate('LI_TABLE_PAGE')}: {paginaActual} / {Math.ceil(maxPaginas / itemPorPagina)} &nbsp;
                                        {paginaFinal ? "" : <button type="button" className="btn btn-secondary btn-sm" onClick={proximaPagina}>{translate('LI_TABLE_BOTON_PAGINA_SIGUIENTE')}</button>}
                                    </th>
                                </tr>
                            </thead>
                            {(i18n.language === 'es') && tablaCA_ES}
                            {(i18n.language === 'en') && tablaCA_EN}
                            {(i18n.language === 'it') && tablaCA_IT}
                        </table>
                    </div>
                    <div className="col-1">
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    )
}

export default ListaInvitados