import { fabric } from 'fabric'
import lottie from 'lottie-web'

fabric.ContadorLottieAC = fabric.util.createClass(fabric.Image, {
  type: 'contadorLottieAC',
  lockRotation: false,
  lockSkewingX: false,
  lockSkewingY: false,
  srcFromAttribute: false,

  initialize: function (path, options) {
    if (options.width === undefined) options.width = 1200
    if (options.height === undefined) options.height = 1200
    if (options.bloqueado_ac === undefined) options.bloqueado_ac = false
    if (options.loop_ac === undefined) options.loop_ac = true
    if (options.autoplay_ac === undefined) options.autoplay_ac = true
    if (options.dia_ac === undefined) options.dia_ac = "01"
    if (options.mes_ac === undefined) options.mes_ac = "01"
    if (options.ano_ac === undefined) options.ano_ac = "2155"
    if (options.color_ac === undefined) options.color_ac = "#000000"

    //this.path = path
    this.path = path
    this.tmpCanvasEl = fabric.util.createCanvasElement()
    this.tmpCanvasEl.width = options.width
    this.tmpCanvasEl.height = options.height

    this.bloqueado_ac = options.bloqueado_ac
    this.loop_ac = options.loop_ac
    this.autoplay_ac = options.autoplay_ac
    this.dia_ac = options.dia_ac
    this.mes_ac = options.mes_ac
    this.ano_ac = options.ano_ac
    this.color_ac = options.color_ac

    this.lottieItem = lottie.loadAnimation({
      renderer: 'canvas',
      loop: this.loop_ac,
      autoplay: this.autoplay_ac,
      path,
      rendererSettings: {
        context: this.tmpCanvasEl.getContext('2d'),
        preserveAspectRatio: 'xMidYMid meet',
      },
    })

    // this.lottieItem.addEventListener('DOMLoaded', () => {
    //   console.log('DOMLoaded')
    // })

    this.lottieItem.addEventListener('enterFrame', (e) => {
      this.canvas?.requestRenderAll()
    })

    this.callSuper('initialize', this.tmpCanvasEl, options);

    options && this.set('bloqueado_ac', options.bloqueado_ac);
    options && this.set('loop_ac', options.loop_ac);
    options && this.set('autoplay_ac', options.autoplay_ac);
    options && this.set('dato_ac', options.dia_ac);
    options && this.set('dato_ac', options.mes_ac);
    options && this.set('dato_ac', options.ano_ac);
    options && this.set('dato_ac', options.color_ac);
  },

  toObject: function() {
    return fabric.util.object.extend(this.callSuper('toObject'), 
      { 
        dia_ac: this.dia_ac,
        mes_ac: this.mes_ac,
        ano_ac: this.ano_ac,
        color_ac: this.color_ac,
        bloqueado_ac: this.bloqueado_ac,
        loop_ac: this.loop_ac, 
        autoplay_ac: this.autoplay_ac}
    );
  },

  play: function () {
    this.lottieItem.play()
  },
  pause: function () {
    this.lottieItem.pause()
  },
  stop: function () {
    this.lottieItem.stop()
  },
  getLoop: function () {
    return this.lottieItem.loop;
  }, 
  changeLoop: function () {
    if(this.lottieItem.loop  === true ){
      this.loop_ac = false;
      this.lottieItem.loop = false;
    } else {
      this.loop_ac = true;
      this.lottieItem.loop = true;
    }
  },
  getAutoplay: function () {
    return this.lottieItem.autoplay;
  }, 
  changeAutoplay: function () {
    if(this.lottieItem.autoplay  === true ){
      this.autoplay_ac = false;
      this.lottieItem.autoplay = false;
    } else {
      this.autoplay_ac = true;
      this.lottieItem.autoplay = true;
    }
  },
  getBloqueado: function () {
    return this.bloqueado_ac;
  }, 
  changeBloqueado: function () {
    if(this.bloqueado_ac  === true ){
      this.bloqueado_ac = false;
    } else {
      this.bloqueado_ac = true;
    }
  },
  getDia: function () {
    return this.dia_ac;
  }, 
  changeDia: function (newDato) {
    this.dia_ac = newDato;
  },
  getMes: function () {
    return this.mes_ac;
  }, 
  changeMes: function (newDato) {
    this.mes_ac = newDato;
  },
  getAno: function () {
    return this.ano_ac;
  }, 
  changeAno: function (newDato) {
    this.ano_ac = newDato;
  },
  getColor: function () {
    return this.color_ac;
  }, 
  changeColor: function (newDato) {
    this.color_ac = newDato;
  },
  getSrc: function () {
    return this.path
  }, 
});

fabric.ContadorLottieAC.fromObject = function (_object, callback) {
  const object = fabric.util.object.clone(_object);
  fabric.Image.prototype._initFilters.call(object, object.filters, function (filters) {
    object.filters = filters || []
    fabric.Image.prototype._initFilters.call(object, [object.resizeFilter], function (resizeFilters) {
      object.resizeFilter = resizeFilters[0]
      fabric.util.enlivenObjects([object.clipPath], function (enlivedProps) {
        object.clipPath = enlivedProps[0]
        const fabricLottie = new fabric.ContadorLottieAC(object.src, object)
        callback(fabricLottie, false)
      })
    })
  })
}

fabric.ContadorLottieAC.async = true;

export default fabric.ContadorLottieAC;