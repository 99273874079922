import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import App from './App';
import './index.css';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';

import enFile from './traducciones/en.json';
import esFile from './traducciones/es.json';
import itFile from './traducciones/it.json';

i18next.init({
  interpolation: {escapeValue: false},
  lng: 'es',
  resources:{
    es: {
      global: esFile
    },
    en: {
      global: enFile
    },
    it: {
      global: itFile
    }    
  }
});

ReactDOM.render(
  <React.StrictMode>
    <HashRouter>
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>  
    </HashRouter>
  </React.StrictMode>,
  document.getElementById('root')
);


