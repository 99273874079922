import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
// Muestra el componente de loadging
import Loading from '../../../Componentes/Loading/Loading';
// Toast 
import { ToastContainer } from 'react-toastify';
import { showErrorToast, showSuccessToast } from '../../../../servicios/toast/toast';

// Gestion de datos del backend invitado
import { postLoginPersonalizado  } from "../../../../servicios/axios/restApi/useApiLoginPersonalizado";

// Componente internacionalizacion
import { useTranslation } from 'react-i18next';

// Zustand
import { useStoreDatosPortada } from './../../../../servicios/stores/usuario/useStoreDatosPortada';

import {sha1} from 'crypto-hash';

import "./AgregarLoginPesonalizado.css"; 

const AgregarLoginPesonalizado = () => {

    //zustand
    const loginCampo1 = useStoreDatosPortada((state) => state.loginCampo1);
    const loginCampo2 = useStoreDatosPortada((state) => state.loginCampo2);
    const loginCampo3 = useStoreDatosPortada((state) => state.loginCampo3);

    // Cambio de idioma
    const [translate, i18n] = useTranslation('global');

    // Variables de componente
    const { codigo, idioma } = useParams();

    const navigate = useNavigate();

    let modeloInvitado = {
        idLoginPersonalizado: '', // Primary Key
        campo1: '',
        campo2: '',
        campo3: '',
        tarjetaCodigo: codigo,
        idioma: idioma,
        urlCodigo: ''
    }

    const [datosDB, setDatosDB] = useState(modeloInvitado);

    const [isLoading, setIsLoading] = useState(false);

    // Funciones de operaciones
    const actualizarDatos = async (event) => {
        event.preventDefault();

        const generarHash = async() => {
            const current = Math.round(+new Date()/1000);

            const cadenaDatos = datosDB.campo1 + String(current) + datosDB.tarjetaCodigo;
            const codigoSha1 = await sha1(cadenaDatos);

            return codigoSha1;
        }

        const solicitarAlta = async () => {
            const codigoSha1 = await generarHash();

            const modeloInvitadoAux = modeloInvitado;
            modeloInvitadoAux.campo1 = datosDB.campo1.replace(/'/g, "''");
            modeloInvitadoAux.campo2 = datosDB.campo2.replace(/'/g, "''");
            modeloInvitadoAux.campo3 = datosDB.campo3.replace(/'/g, "''");
            modeloInvitadoAux.tarjetaCodigo = datosDB.tarjetaCodigo;
            modeloInvitadoAux.idioma = datosDB.idioma;
            modeloInvitadoAux.urlCodigo = codigoSha1;

            const respuesta = await postLoginPersonalizado(modeloInvitadoAux);
            if( respuesta.status === 'ok' ){
                showSuccessToast(translate('LP_AGREGAR_001'));
            }else{
                showErrorToast(translate('LP_AGREGAR_002'));
            }
        }

        try{
            if( datosDB.campo1.length > 0 ){
                setIsLoading(true);
                await solicitarAlta();
                setIsLoading(false);
                volver();                
            }else{
                if(datosDB.campo1.length === 0) showErrorToast(translate('LP_AGREGAR_003'));
            }
        }catch(e){
            setIsLoading(false);
            showErrorToast(translate('LI_ERROR_001'));
        }
    }

    const volver = () => {
        navigate("/LP?codigo=" + codigo + '&idioma=' + idioma);
    }

    // Muestra el componente de carga cuando se hace una operación en contra la BD
    // funciones de campos
    const handleInputChange = event => {
        const { name, value } = event.target
        setDatosDB({ ...datosDB, [name]: value })
    }

    const handleCheckBoxChange = event => {
        const { name, checked } = event.target
        setDatosDB({ ...datosDB, [name]: (checked ? '1' : '0') })
    }

    const handleRadioButtonChange = event => {
        const { name, value } = event.target
        setDatosDB({ ...datosDB, [name]: value })
    }

    
    // funciones de pantallas
    const pantallaLoginPersonalizado = (
        <div className="container-fluid bg-white text-white pt-2 pb-2 text-center">
            <div className="row">
                <div className="col-12">
                    <h1 style={{ color: "black", fontSize: "1.3rem" }}>{translate('LP_TABLE_TITULO_NUEVO')}</h1>
                </div>
            </div>
            <div className="row">
                <div className="col-1">
                </div>
                <div className="col-10">
                    <div className="row pt-1">
                        <div className="col-6" style={{backgroundColor:'transparent'}}>
                            <form onSubmit={actualizarDatos}>
                                <button type="submit" className="btn btn-primary btn-sm" >{translate('LP_BOTON_NUEVO')}</button>
                                &nbsp;&nbsp;
                                <button type="button" className="btn btn-dark btn-sm" onClick={volver}>{translate('LI_EDITAR_VOLVER')}</button>

                                <br />

                                <div className="row">
                                    <div  className="col-3"></div>
                                    <div  className="col-6" style={{textAlign:"left"}}>
                                        <label htmlFor="campo1" style={{color:"black", fontSize:"1.1rem"}}>{translate('LP_CAMPO_1')}</label>
                                        <span>: &nbsp; </span>
                                        <input className="form-control" type="text"
                                            name="campo1" id="campo1"
                                            onChange={handleInputChange} value={datosDB.campo1}
                                        />
                                    </div><div  className="col-3"></div>
                                </div> 

                                <div className="row">
                                    <div  className="col-3"></div>
                                    <div  className="col-6" style={{textAlign:"left"}}>
                                        <label htmlFor="campo2" style={{color:"black", fontSize:"1.1rem"}}>{translate('LP_CAMPO_2')}</label>
                                        <span>: &nbsp; </span>
                                        <input className="form-control" type="text"
                                            name="campo2" id="campo2"
                                            onChange={handleInputChange} value={datosDB.campo2}
                                        />
                                    </div><div  className="col-3"></div>
                                </div>                        

                                <div className="row">
                                    <div  className="col-3"></div>
                                    <div  className="col-6" style={{textAlign:"left"}}>
                                        <label htmlFor="campo3" style={{color:"black", fontSize:"1.1rem"}}>{translate('LP_CAMPO_3')}</label>
                                        <span>: &nbsp; </span>
                                        <input className="form-control" type="text"
                                            name="campo3" id="campo3"
                                            onChange={handleInputChange} value={datosDB.campo3}
                                        />
                                    </div><div  className="col-3"></div>
                                </div>  

                                <div className="row">
                                    <div  className="col-3"></div>
                                    <div  className="col-6" style={{textAlign:"left"}}>
                                        <label htmlFor="tarjetaCodigo" style={{color:"black", fontSize:"1.1rem"}}>{translate('LP_CAMPO_CODIGO')}</label>
                                        <span>: &nbsp; </span>
                                        <input className="form-control" type="text" disabled
                                            name="tarjetaCodigo" id="tarjetaCodigo"
                                            onChange={handleInputChange} value={datosDB.tarjetaCodigo}
                                        />
                                    </div><div  className="col-3"></div>
                                </div>  

                                <div className="row">
                                    <div  className="col-3"></div>
                                    <div  className="col-6" style={{textAlign:"left"}}>
                                        <label htmlFor="idioma" style={{color:"black", fontSize:"1.1rem"}}>{translate('LP_CAMPO_IDIOMA')}</label>
                                        <span>: &nbsp; </span>
                                        <input className="form-control" type="text" disabled
                                            name="idioma" id="idioma"
                                            onChange={handleInputChange} value={datosDB.idioma}
                                        />
                                    </div><div  className="col-3"></div>
                                </div>                          

                                {/* <div className="row">
                                    <div  className="col-3"></div>
                                    <div  className="col-6" style={{textAlign:"left"}}>
                                        <label htmlFor="urlCodigo" style={{color:"black", fontSize:"1.1rem"}}>{translate('LP_CAMPO_URL_CODE')}</label>
                                        <span>: &nbsp; </span>
                                        <input className="form-control" type="text" disabled
                                            name="urlCodigo" id="urlCodigo" 
                                            onChange={handleInputChange} value={datosDB.urlCodigo}
                                        />
                                    </div><div  className="col-3"></div>
                                </div>  */}

                            </form>
                        </div>    
                        <div className="col-6" style={{backgroundColor:'transparent'}}>
                            <div className="card" id="LoginPersonalizado" style={{ border: '1px dashed lightslategray', width: '23rem', height: '40rem', backgroundColor: 'whitesmoke', position:'relative'}}>
                                <div className="card-body">
                                    <label htmlFor="" style={{paddingTop:'0.1rem'}}>&nbsp;</label>
                                    <h1 className='t1' style={{color:'black'}} content="notranslate"> {datosDB.campo1} </h1>
                                    <h1 className='t2' style={{color:'black'}} content="notranslate"> {datosDB.campo2} </h1>
                                    <h1 className='t3' style={{color:'black'}} content="notranslate"> {datosDB.campo3} </h1>
                                    <center><h1 style={{color:'black'}} className='t4' content="notranslate"> {loginCampo1} </h1></center> 
                                    <img id="swipeUp" src="./assets/img/PantallaClickPersonalizado.svg" alt=""></img>
                                    <h1 style={{color:'black'}} className='t5' content="notranslate"> {loginCampo2} </h1>
                                    <h1 style={{color:'black'}} className='t6' content="notranslate"> {loginCampo3} </h1> 
                                </div>
                            </div>   
                        </div>            
                    </div>   



                </div>
                <div className="col-1">
                </div>
            </div>
        </div>
    );

    return (
        <>
            {isLoading ? <Loading /> : pantallaLoginPersonalizado}
            <ToastContainer />
        </>
    )
}

export default AgregarLoginPesonalizado;