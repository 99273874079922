import axios from "axios";

const apiURLBase = process.env.REACT_APP_INVITADO_BACKEND_URL;

const _imprimiConsulta = (url) => {
    if(process.env.REACT_APP_ENTORNO === "DESA"){
        console.log(url);
    }
}

// Busca si el fondo de tarjeta esta registrado
export const getInvitadosPaginado = async (pagina, count='25', hashPrivado='0', filtro='') => {
    const url = apiURLBase + "rsvp.php?page=" + pagina + "&count=" + count + "&hashPrivado=" + hashPrivado + '&filtro=' + filtro;
    _imprimiConsulta(url);
    const { data } = await axios.get(url);
    return data;
}

// Busca si el fondo de tarjeta esta registrado
export const getCantidadDeInvitados =  async (hashPrivado='', filtro='') => {
    const url = apiURLBase + "rsvp.php?total=total&hashPrivado=" + hashPrivado + '&filtro=' + filtro;;
    _imprimiConsulta(url);
    const { data } = await axios.get(url);
    return data;
}

// recupera todos los fondos de tarjetas
export const getAllInvitados =  async (hashPrivado='0') => {
    const url = apiURLBase + "rsvp.php?all=all&hashPrivado=" + hashPrivado;
    _imprimiConsulta(url);
    const { data } = await axios.get(url);
    return data;
}

// recupera todos las definiciones de campos RSVP de la tarjetas (Estructura no valores)
export const getAllRsvpCamposHashPublico =  async (hashPublico='' ) => {
    const url = apiURLBase + "rsvp.php?allCamposRSVP=allCamposRSVP&hashPublico=" + hashPublico;
    _imprimiConsulta(url);
    const { data } = await axios.get(url);
    return data;
}

// Busca si el fondo de tarjeta esta registrado
export const getInvitadoById =  async (idRSVP, hashPrivado) => {
    const url = apiURLBase + "rsvp.php?idRsvp=" + idRSVP + "&hashPrivado=" + hashPrivado;
    _imprimiConsulta(url);
    const { data } = await axios.get(url);
    return data;
}

// Registra un nuevo fondo de tarjeta
export const postInvitado = async (modeloInvitado) => {
    const url = apiURLBase + "rsvp.php";  
    _imprimiConsulta(url);
    const { data } = await axios.post(url, modeloInvitado);
    return data;
};

// Actualizar los datos de un fondo de tarjeta
export const putInvitado = async (modeloInvitado, idRSVP, hashPublico, hashPrivado) => {
    const url = apiURLBase + "rsvp.php?idRsvp=" + idRSVP + "&hashPublico=" + hashPublico + "&hashPrivado=" + hashPrivado;  
    _imprimiConsulta(url);
    const { data } = await axios.put(url, modeloInvitado);
    return data;
};

// Delete los datos de un fondo de tarjeta
export const deleteInvitado = async (idRSVP, hashPublico, hashPrivado) => {
    const url = apiURLBase + "rsvp.php?idRsvp=" + idRSVP + "&hashPublico=" + hashPublico + "&hashPrivado=" + hashPrivado;  
    _imprimiConsulta(url);
    const { data } = await axios.delete(url);
    return data;
};
