import React, { useEffect, useState } from "react";

// Gestion de datos del backend invitado
import { getAllRsvpCamposHashPublico } from "../../../servicios/axios/restApi/useApiInvitados";

// Componente internacionalizacion
import { useTranslation } from 'react-i18next';

import "./FormularioModal.css";

const FormularioModal = ({ datoObjetoLottie, invitado, setInvitado, codigoIngresado }) => {

    // Cambio de idioma
    const [translate, i18n] = useTranslation('global');

    const [camposRSVP, setCamposRSVP] = useState([]);

    useEffect(() => {
        const buscarCamposRSVP = async () => {
            const camposRsvpAux = await getAllRsvpCamposHashPublico(codigoIngresado);
            setCamposRSVP(camposRsvpAux);

            const updatedInvitado = { ...invitado }; // Create a temporary object

            camposRsvpAux.forEach((campoRsvp) => {
                let valores = {};
                if (campoRsvp.tipoCampo === "combo" && campoRsvp.tituloCampo.length > 0) {
                    updatedInvitado[campoRsvp.nombreCampo] = 0;
                } 
                if (campoRsvp.tipoCampo === "seleccion" && campoRsvp.tituloCampo.length > 0) {
                    updatedInvitado[campoRsvp.nombreCampo] = "No";
                } 
                
            });

            setInvitado(updatedInvitado); // Update the state once
            //console.log(invitado);
        };

        console.log("datoObjetoLottie " + datoObjetoLottie)
        buscarCamposRSVP();
    }, []);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setInvitado({ ...invitado, [name]: value });
        //console.log(invitado);
    };

    const handleCheckBoxChange = (event) => {
        const { name, checked } = event.target;
        setInvitado({ ...invitado, [name]: checked ? "1" : "0" });
    };

    const handleRadioButtonChange = (event) => {
        //console.log(event.target.checked)
        const { name, value } = event.target;
        setInvitado({ ...invitado, [name]: value });
    };

    const getValorDinamico = (campo) => {
        let valor =  "";
        switch (campo) {
            case "campo1": valor = invitado.campo1; break;
            case "campo2": valor = invitado.campo2; break;
            case "campo3": valor = invitado.campo3; break;
            case "campo4": valor = invitado.campo4; break;
            case "campo5": valor = invitado.campo5; break;
            case "campo6": valor = invitado.campo6; break;
            case "campo7": valor = invitado.campo7; break;
            case "campo8": valor = invitado.campo8; break;
            case "campo9": valor = invitado.campo9; break;
            case "campo10": valor = invitado.campo10; break;
            default: valor = ""; 
        }

        return valor;
    }

    const crearFormularioContacto = () => {
        const htmlElementos = [];

        camposRSVP.forEach((campoRsvp) => {
            if (campoRsvp.tipoCampo === "cadena" && campoRsvp.tituloCampo.length > 0) {
                const valorAtt = "invitado." + campoRsvp.nombreCampo;
                htmlElementos.push(
                    <>
                        <label htmlFor={campoRsvp.nombreCampo}>
                            <strong>{campoRsvp.tituloCampo}</strong>
                        </label>
                        {/* <span>: &nbsp; </span> */}
                        <input
                            className="form-control"
                            type="text"
                            name={campoRsvp.nombreCampo}
                            id={campoRsvp.nombreCampo}
                            onChange={handleInputChange}
                            value={getValorDinamico(campoRsvp.nombreCampo)}
                        />
                    </>
                );
            }
            if (campoRsvp.tipoCampo === "combo" && campoRsvp.tituloCampo.length > 0) {
                htmlElementos.push(
                    <>
                        <label htmlFor={campoRsvp.nombreCampo} style={{ marginTop: `7px` }}>
                            <strong>{campoRsvp.tituloCampo}</strong>
                        </label>
                        <span>: &nbsp; </span>
                        <select
                            className="custom-select mr-sm-2 combos"
                            name={campoRsvp.nombreCampo}
                            id={campoRsvp.nombreCampo}
                            onChange={handleInputChange}
                            value={getValorDinamico(campoRsvp.nombreCampo)}
                        >
                            <option value={0}>0</option>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value={6}>6</option>
                            <option value={7}>7</option>
                            <option value={8}>8</option>
                            <option value={9}>9</option>
                            <option value={10}>10</option>
                        </select>
                        <br />
                    </>
                );
            }
            if (campoRsvp.tipoCampo === "seleccion" && campoRsvp.tituloCampo.length > 0) {
                htmlElementos.push(
                    <>
                        <label htmlFor={campoRsvp.nombreCampo} style={{ marginTop: `0px` }}>
                            <strong>{campoRsvp.tituloCampo}</strong>
                        </label>
                        <br></br>
                        <label>
                            <input
                                name={campoRsvp.nombreCampo}
                                id={campoRsvp.nombreCampo}
                                checked={getValorDinamico(campoRsvp.nombreCampo) === "No" ? true : false}
                                type="radio"
                                value="No"
                                onChange={handleRadioButtonChange}
                            />
                            &nbsp;{translate('ALL_VALOR_NO')}
                        </label>
                        &nbsp;&nbsp;/&nbsp;&nbsp;
                        <label>
                            <input
                                name={campoRsvp.nombreCampo}
                                id={campoRsvp.nombreCampo}
                                checked={getValorDinamico(campoRsvp.nombreCampo) === "Si" ? true : false}
                                type="radio"
                                value="Si"
                                onChange={handleRadioButtonChange}
                            />
                            &nbsp;{translate('ALL_VALOR_SI')}
                        </label>
                        <br />
                    </>
                );
            }
            //console.log(htmlElementos);
        });

        return <>{htmlElementos}</>;
    };

    return (
        <div className="form-group">
            { datoObjetoLottie !== undefined && ( datoObjetoLottie.length > 0 ?  <h1 className="formbody nombres">{datoObjetoLottie}</h1> : "" ) }
            <div>{crearFormularioContacto()}</div>
        </div>
    );
};

export default FormularioModal;
