import { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
// Muestra el componente de loadging
import Loading from '../../../Componentes/Loading/Loading';
// Toast 
import { ToastContainer } from 'react-toastify';
import { showErrorToast, showSuccessToast } from '../../../../servicios/toast/toast';

// Gestion de datos del backend invitado
import { getInvitadoById, putInvitado, deleteInvitado, getAllRsvpCamposHashPublico } from "../../../../servicios/axios/restApi/useApiInvitados";

// Modal de confirmacion
import ModalConfirmacion from './../../../Componentes/ModalConfirmacion/ModalConfirmacion';

// Componente internacionalizacion
import { useTranslation } from 'react-i18next';

const EditarInvitado = () => {

    let { search } = useLocation();
    let query = new URLSearchParams(search);

    // Cambio de idioma
    const [translate, i18n] = useTranslation('global');

    // Variables de componente
    const { id, hashPrivado, hashPublico, idioma } = useParams();

    const navigate = useNavigate();

    let modeloInvitado = {
        idRsvp: '', // Primary Key
        campo1: '',
        campo2: '',
        campo3: '',
        campo4: '',
        campo5: '',
        campo6: '',
        campo7: '',
        campo8: '',
        campo9: '',
        campo10: '',
        hashPublico: '',
        hashPrivado: ''
    }

    const [datosDB, setDatosDB] = useState("");

    const [isLoading, setIsLoading] = useState(false);

    const [mostrarModal, setMostrarModal] = useState(false);

    const [camposRSVP, setCamposRSVP] = useState([]);

    useEffect(() => {
        setIsLoading(true);

        if( idioma === 'ES' ){
            i18n.changeLanguage('es');
        }else if( idioma === 'IT' ){
            i18n.changeLanguage('it');
        }else{
            i18n.changeLanguage('en');
        }

        const buscarCamposRSVP = async () => {
            const camposRsvpAux = await getAllRsvpCamposHashPublico(hashPublico);
            setCamposRSVP(camposRsvpAux);
        };

        const cargaDatosInvitado = async () => {
            const datosDBAux = await getInvitadoById(id, hashPrivado);

            modeloInvitado.idRsvp = datosDBAux[0].idRsvp;
            modeloInvitado.campo1 = datosDBAux[0].campo1;
            modeloInvitado.campo2 = datosDBAux[0].campo2;
            modeloInvitado.campo3 = datosDBAux[0].campo3;
            modeloInvitado.campo4 = datosDBAux[0].campo4;
            modeloInvitado.campo5 = datosDBAux[0].campo5;
            modeloInvitado.campo6 = datosDBAux[0].campo6;
            modeloInvitado.campo7 = datosDBAux[0].campo7;
            modeloInvitado.campo8 = datosDBAux[0].campo8;
            modeloInvitado.campo9 = datosDBAux[0].campo9;
            modeloInvitado.campo10 = datosDBAux[0].campo10;
            modeloInvitado.hashPublico = datosDBAux[0].hashPublico;
            modeloInvitado.hashPrivado = datosDBAux[0].hashPrivado;

            //console.log(modeloInvitado);

            setDatosDB(modeloInvitado);

            setIsLoading(false);
        }

        try {
            if (id > 0) {
                buscarCamposRSVP();
                cargaDatosInvitado();
            } else {
                setIsLoading(false);
                showErrorToast("Datos incorrectos. - EditarInvitado.jsx");
            }

        } catch (e) {
            setIsLoading(false);
            showErrorToast("No se pudo recurperar los datos del servidor - EditarInvitado.jsx");
        }
    }, []);

    // Funciones de operaciones
    const actualizarDatos = async (event) => {
        event.preventDefault();

        try {
            if (datosDB.campo1.length > 0) {
                setIsLoading(true);

                datosDB.campo1 = datosDB.campo1.replace(/'/g, "''");
                datosDB.campo2 = datosDB.campo2.replace(/'/g, "''");
                datosDB.campo3 = datosDB.campo3.replace(/'/g, "''");
                datosDB.campo4 = datosDB.campo4.replace(/'/g, "''");
                datosDB.campo5 = datosDB.campo5.replace(/'/g, "''");
                datosDB.campo6 = datosDB.campo6.replace(/'/g, "''");
                datosDB.campo7 = datosDB.campo7.replace(/'/g, "''");
                datosDB.campo8 = datosDB.campo8.replace(/'/g, "''");
                datosDB.campo9 = datosDB.campo9.replace(/'/g, "''");
                datosDB.campo10 = datosDB.campo10.replace(/'/g, "''");

                const respuesta = await putInvitado(datosDB, datosDB.idRsvp, hashPublico, hashPrivado);

                if (respuesta.status === 'ok') {
                    showSuccessToast("Datos actualizados");
                } else {
                    setIsLoading(false);
                    showErrorToast("Error al actualizar de datos");
                }
                setIsLoading(false);
                volver();
            } else {
                if (datosDB.campo1.length === 0) showErrorToast("Se debe ingresar un valor en el campo nombre");
            }

        } catch (e) {
            setIsLoading(false);
            showErrorToast("No se pudo actualizar los datos del servidor - EditarInvitado.jsx");
        }
    }

    const openModelConfirmacion = () => {
        setMostrarModal(true)
    }

    const eliminarOperacion = async () => {
        setIsLoading(true);

        try {
            const respuesta = await deleteInvitado(datosDB.idRsvp, hashPublico, hashPrivado);

            if (respuesta.status === 'ok') {
                showSuccessToast("Modelo tarjeta eliminado");
            } else {
                showErrorToast("Error al eliminar modelo tarjeta");
            }

            setIsLoading(false);
            volver();
        } catch (e) {
            setIsLoading(false);
            showErrorToast("No se pudo eliminar los datos del servidor - EditarModeloTarjeta.jsx");
        }
    }

    const volver = () => {
        navigate("/LI?hashPublico=" + hashPublico + '&hashPrivado=' + hashPrivado + '&idioma=' + idioma);
    }

    // Muestra el componente de carga cuando se hace una operación en contra la BD
    // funciones de campos
    const handleInputChange = event => {
        const { name, value } = event.target
        setDatosDB({ ...datosDB, [name]: value })
    }

    const handleCheckBoxChange = event => {
        const { name, checked } = event.target
        setDatosDB({ ...datosDB, [name]: (checked ? '1' : '0') })
    }

    const handleRadioButtonChange = event => {
        const { name, value } = event.target
        setDatosDB({ ...datosDB, [name]: value })
    }

    const getValorDinamico = (campo) => {
        let valor = "";
        switch (campo) {
            case "campo1": valor = datosDB.campo1; break;
            case "campo2": valor = datosDB.campo2; break;
            case "campo3": valor = datosDB.campo3; break;
            case "campo4": valor = datosDB.campo4; break;
            case "campo5": valor = datosDB.campo5; break;
            case "campo6": valor = datosDB.campo6; break;
            case "campo7": valor = datosDB.campo7; break;
            case "campo8": valor = datosDB.campo8; break;
            case "campo9": valor = datosDB.campo9; break;
            case "campo10": valor = datosDB.campo10; break;
            default: valor = "";
        }

        return valor;
    }

    const crearFormularioContacto = () => {
        const htmlElementos = [];

        camposRSVP.forEach((campoRsvp) => {
            if (campoRsvp.tipoCampo === "cadena" && campoRsvp.tituloCampo.length > 0) {
                const valorAtt = "invitado." + campoRsvp.nombreCampo;
                htmlElementos.push(
                    <>
                        <div className="row">
                            <div className="col-3"></div>
                            <div className="col-6" style={{ textAlign: "left" }}>
                                <label htmlFor={campoRsvp.nombreCampo} style={{ color: "black", fontSize: "1.1rem" }}>
                                    {campoRsvp.tituloCampo}
                                </label>
                                <span>: &nbsp; </span>
                                <input
                                    className="form-control"
                                    type="text"
                                    name={campoRsvp.nombreCampo}
                                    id={campoRsvp.nombreCampo}
                                    onChange={handleInputChange}
                                    value={getValorDinamico(campoRsvp.nombreCampo)}
                                />
                            </div>
                            <div className="col-3"></div>
                        </div>
                    </>
                );
            }
            if (campoRsvp.tipoCampo === "combo" && campoRsvp.tituloCampo.length > 0) {
                htmlElementos.push(
                    <>
                        <div className="row">
                            <div className="col-3"></div>
                            <div className="col-6" style={{ textAlign: "left" }}>
                                <label htmlFor={campoRsvp.nombreCampo} style={{ color: "black", fontSize: "1.1rem", marginTop: `7px` }}>
                                    {campoRsvp.tituloCampo}
                                </label>
                                <span>: &nbsp; </span>
                                <select
                                    className="custom-select mr-sm-2 combos"
                                    name={campoRsvp.nombreCampo}
                                    id={campoRsvp.nombreCampo}
                                    onChange={handleInputChange}
                                    value={getValorDinamico(campoRsvp.nombreCampo)}
                                >
                                    <option value={0}>0</option>
                                    <option value={1}>1</option>
                                    <option value={2}>2</option>
                                    <option value={3}>3</option>
                                    <option value={4}>4</option>
                                    <option value={5}>5</option>
                                    <option value={6}>6</option>
                                    <option value={7}>7</option>
                                    <option value={8}>8</option>
                                    <option value={9}>9</option>
                                    <option value={10}>10</option>
                                </select>
                            </div>
                            <div className="col-3"></div>
                        </div>
                    </>
                );
            }
            if (campoRsvp.tipoCampo === "seleccion" && campoRsvp.tituloCampo.length > 0) {
                htmlElementos.push(
                    <>
                        <div className="row">
                            <div className="col-3"></div>
                            <div className="col-6" style={{ textAlign: "left" }}>
                                <label htmlFor={campoRsvp.nombreCampo} style={{ color: "black", fontSize: "1.1rem", marginTop: `7px` }}>
                                    <strong>{campoRsvp.tituloCampo}:&nbsp;</strong>
                                </label>
                                <br></br>
                                <label style={{ color: "black", fontSize: "1.1rem", marginTop: `7px` }}>
                                    <input
                                        name={campoRsvp.nombreCampo}
                                        id={campoRsvp.nombreCampo}
                                        checked={getValorDinamico(campoRsvp.nombreCampo) === "No" ? true : false}
                                        type="radio"
                                        value="No"
                                        onChange={handleRadioButtonChange}
                                    />
                                    &nbsp;{translate('ALL_VALOR_NO')}
                                </label>
                                &nbsp;&nbsp;/&nbsp;&nbsp;
                                <label style={{ color: "black", fontSize: "1.1rem", marginTop: `7px` }}>
                                    <input
                                        name={campoRsvp.nombreCampo}
                                        id={campoRsvp.nombreCampo}
                                        checked={getValorDinamico(campoRsvp.nombreCampo) === "Si" ? true : false}
                                        type="radio"
                                        value="Si"
                                        onChange={handleRadioButtonChange}
                                    />
                                    &nbsp;{translate('ALL_VALOR_SI')}
                                </label>
                            </div>
                            <div className="col-3"></div>
                        </div>
                    </>
                );
            }
            //console.log(htmlElementos);
        });

        return <>{htmlElementos}</>;
    };

    // funciones de pantallas
    const pantallaInvitado = (
        <div className="container-fluid bg-white text-white pt-2 pb-2 text-center">
            <div className="row">
                <div className="col-12">
                    <h1 style={{ color: "black", fontSize: "1.1rem" }}>{translate('LI_EDITAR_TITULO')}</h1>
                </div>
            </div>
            <div className="row">
                <div className="col-1">
                </div>
                <div className="col-10">
                    <form onSubmit={actualizarDatos}>
                        <br />

                        <button type="submit" className="btn btn-primary btn-sm" >{translate('LI_EDITAR_ACTUALIZAR')}</button>&nbsp;&nbsp;
                        <button type="button" className="btn btn-danger btn-sm" onClick={openModelConfirmacion}>{translate('LI_EDITAR_ELIMINAR')}</button>&nbsp;&nbsp;
                        <button type="button" className="btn btn-dark btn-sm" onClick={volver}>{translate('LI_EDITAR_VOLVER')}</button>

                        <br /><br />

                        <div className="row">{crearFormularioContacto()}</div>

                    </form>
                </div>
                <div className="col-1">
                </div>
            </div>
        </div>
    );

    const pantallaInvitadoNoEncontrado = (
        <div className="container-fluid bg-white text-white pt-2 pb-2 text-center">
            <div className="row">
                <div className="col-1">
                </div>
                <div className="col-10">
                    <div>
                        <br />
                        <button type="button" className="btn btn-dark btn-sm" onClick={volver}>{translate('LI_EDITAR_VOLVER')}</button>
                        <br />
                    </div>
                    <div>
                        <p style={{ 'color': 'black' }}>{translate('LI_EDITAR_NO_HAY_DATOS')}</p>
                    </div>
                </div>
                <div className="col-1">
                </div>
            </div>
        </div>
    );

    return (
        <>
            {isLoading ? <Loading /> : ""}
            {datosDB ? pantallaInvitado : pantallaInvitadoNoEncontrado}
            {mostrarModal ? <ModalConfirmacion titulo={translate('LI_EDITAR_MODAL_TITULO')} texto={translate('LI_EDITAR_MODAL_DESCRIPCION')} mostrarModal={mostrarModal} setMostrarModal={setMostrarModal} eliminarOperacion={eliminarOperacion} /> : ""}
            <ToastContainer />
        </>
    )
}

export default EditarInvitado